import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@material-ui/core";
import {
  ActionButton,
  ActionLink,
  B2BSpinnerWithText,
  GenericModalContent,
  Icon,
  IconName,
} from "halifax";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  Airline,
  BookedFlightItineraryWithDepartureTime,
  ExchangeModalState,
  ExchangeScenario,
  FlightRatingsEnum,
  FTCScenario,
  IAirlineControlExchangePolicy,
  IContactAirlineRes,
  IContactCustomerServiceRes,
  IFlightVoidAndExchangePolicyRes,
  IFTCExchangePolicy,
  INonChfarContactAirlineRes,
  INonChfarContactCustomerServiceRes,
  INonChfarExchangePolicy,
  INonChfarNonExchangeableRes,
  INonChfarTicketedVoidableRes,
  INonExchangeableRes,
  ITicketedVoidableRes,
  MODAL_ALERT,
  ModalAlertProperties,
  ModalCategoryType,
  MultiTicketTypeEnum,
  MultiTravelItinerary,
  MyTripsModalTypes,
  NonChfarScenario,
  PortalItineraryStatusEnum,
  SelfServeEvents,
  SingleTravelItinerary,
  TrackEventRequest,
  TravelItinerary,
  TravelItineraryEnum,
  VoidScenario,
} from "redmond";
import { isCorpTenant } from "@capone/common";
import { useExperimentsById } from "@capone/experiments";

import { trackEvent } from "../../../../../../../../api/v1/analytics/trackEvent";
import fetchFlightExchangePolicy from "../../../../../../../../api/v1/itinerary/fetchFlightExchangePolicy";
import fetchFlightExchangePolicyV2 from "../../../../../../../../api/v1/itinerary/fetchFlightExchangePolicyV2";
import { IStoreState } from "../../../../../../../../reducers/types";
import { PATH_EXCHANGE_FLIGHTS } from "../../../../../../../../utils/paths";
import { setOpenModal } from "../../../../../../actions/actions";
import { getViewedFlightExchangeModalProperties } from "../../../../../../reducer";
import { addFlightType } from "../../../FlightCard/helpers";
import { buttonText, copy, openSupportInNewTab } from "./constants";

import "./styles.scss";
import { config } from "../../../../../../../../api/config";
import { CONTACT_SUPPORT_NUMBER } from "../../../../../../../common/MyTripsHeader/constants";
import {
  ActiveExperiments,
  useExperiment,
} from "../../../../../../../../context/experiments";
import { PostTicketingStatus } from "redmond/build/trips-module/itinerary";

const corpErrorModalEventProperties: ModalAlertProperties = {
  type: "flight_exchange_error",
  primary_button: "",
  secondary_button: "",
  screen: "my_trips",
  modal_title: copy.LOAD_FAILED_TITLE,
  modal_subtitle: copy.LOAD_FAILED_SUBTITLE("capone-corporate"),
  category: ModalCategoryType.FEATURE,
  agent_title: copy.LOAD_FAILED_TITLE,
  agent_subtitle: copy.LOAD_FAILED_SUBTITLE("capone-corporate"),
  step: "",
  funnel: "other",
};

export interface IExchangeFlightModalContentProps extends RouteComponentProps {
  flight: BookedFlightItineraryWithDepartureTime;
  isMultiCity: boolean;
}

const defaultProps: Partial<IExchangeFlightModalContentProps> = {};

const ExchangeFlightModalContent = (
  props: IExchangeFlightModalContentProps
): JSX.Element => {
  const { flight, history, isMultiCity } = props;
  const dispatch = useDispatch();
  const voidAndExchangePolicyRef = useRef<IFlightVoidAndExchangePolicyRes>();
  const modalDisclaimerRef = useRef("");
  const modalSubtitleRef = useRef<ReactNode>(null);
  const modalTitleRef = useRef("");
  const viewedModalProperties = useSelector((state: IStoreState) =>
    getViewedFlightExchangeModalProperties(state, flight)
  );
  const [modalState, setModalState] = useState<ExchangeModalState>(
    ExchangeModalState.LoadingOrProcessing
  );
  const useFareRulesServiceExp = useExperiment(
    ActiveExperiments.FlightExchangeUseFareRulesService
  );

  /**
   * @description Updates the redux store to close this modal
   */
  const closeModal = () => {
    const closedModal = { type: null, selectedItinerary: null };

    dispatch(setOpenModal(closedModal));
  };

  const getExchangePolicy = useCallback(async () => {
    const { id: itineraryId } = flight.bookedItinerary;
    setModalState(ExchangeModalState.LoadingOrProcessing);

    if (isMultiCity && isCorpTenant(config.TENANT)) {
      modalTitleRef.current = copy.LOAD_FAILED_TITLE;
      modalSubtitleRef.current = [
        copy.LOAD_FAILED_SUBTITLE(config.TENANT),
        CONTACT_SUPPORT_NUMBER,
      ];
      setModalState(ExchangeModalState.LoadFailed);
      return;
    }

    const isMultiTicket =
      flight.bookedItinerary.multiTicketType !== MultiTicketTypeEnum.Single;

    let flightExchangePolicy;
    // Experiment to use b2b-interfaces endpoints as V2. MultiTicket not yet supported on v2
    if (useFareRulesServiceExp && !isMultiTicket) {
      flightExchangePolicy = fetchFlightExchangePolicyV2(itineraryId);
    } else {
      flightExchangePolicy = fetchFlightExchangePolicy(itineraryId);
    }

    try {
      voidAndExchangePolicyRef.current = await flightExchangePolicy;

      // Skip eligibility call for known exceptions
      // Also handled in exchange-module `FlightSearch`
      if (
        shouldOverrideEligibilityToContactSupport(
          flight.bookedItinerary.travelItinerary
        )
      ) {
        modalSubtitleRef.current = [
          "For any questions regarding your trip, please contact our support team and we'd be happy to help.",
          "<br/><b>844-422-6922</b>",
        ];
        modalTitleRef.current = "Contact Support";
        setModalState(ExchangeModalState.ContactSupport);
      } else {
        handlePolicy();
      }
    } catch (e) {
      setModalState(ExchangeModalState.LoadFailed);
    }
  }, [flight]);

  const showExchangeFlow =
    useExperimentsById("corp-ca-1383")?.variant === "available";

  const goToExchange = () => {
    //TODO: temporarily show error modal for all corporate users trying to exchange flight
    if (isCorpTenant(config.TENANT) && !showExchangeFlow) {
      setModalState(ExchangeModalState.LoadFailed);
    } else {
      closeModal();
      history.push({
        pathname: PATH_EXCHANGE_FLIGHTS,
        search: `?bookingId=${flight.bookedItinerary.id}`,
      });
    }
  };

  const shouldOverrideEligibilityToContactSupport = (
    travelItinerary: TravelItinerary
  ): boolean => {
    let shouldOverride = false;
    switch (travelItinerary.TravelItinerary) {
      case TravelItineraryEnum.SingleTravelItinerary:
        shouldOverride =
          shouldOverrideSingleItineraryEligibilityToContactSupport(
            travelItinerary as SingleTravelItinerary
          );
        break;
      case TravelItineraryEnum.MultiTravelItinerary:
        travelItinerary = travelItinerary as MultiTravelItinerary;
        shouldOverride = travelItinerary.travelItineraries.some((itinerary) => {
          return shouldOverrideSingleItineraryEligibilityToContactSupport(
            itinerary
          );
        });
        break;
      default:
        break;
    }
    return shouldOverride;
  };

  const shouldOverrideSingleItineraryEligibilityToContactSupport = (
    travelItinerary: SingleTravelItinerary
  ): boolean => {
    const isAmadeus = travelItinerary.locators?.agent.provider === "amadeus";
    const shouldBlockFareClass = travelItinerary.slices.some((slice) => {
      const fareShelf = slice.fareShelf?.rating;
      return (
        !fareShelf ||
        fareShelf === FlightRatingsEnum.premium.valueOf() ||
        fareShelf === FlightRatingsEnum.luxury.valueOf()
      );
    });

    const itineraryStatusNotEligible =
      flight.status == PortalItineraryStatusEnum.Modified ||
      travelItinerary.postTicketingStatus == PostTicketingStatus.Modified;

    return itineraryStatusNotEligible || (isAmadeus && shouldBlockFareClass);
  };

  const goToSelfServeFlightCancel = () => {
    const { tripAncillaries } = flight.bookedItinerary;
    const hasCfar = Boolean(tripAncillaries.cfarId);

    const selfServeFlightCancelModal = {
      selectedItinerary: addFlightType(flight),
      type: hasCfar
        ? MyTripsModalTypes.CfarFlight
        : MyTripsModalTypes.SelfServeCancelFlight,
    };

    dispatch(setOpenModal(selfServeFlightCancelModal));
  };

  const handlePolicy = useCallback(() => {
    const { current: voidAndExchangePolicy } = voidAndExchangePolicyRef;
    if (voidAndExchangePolicy) {
      const { voidScenario: voidScenario, exchangeScenario: exchangeScenario } =
        voidAndExchangePolicy;

      const { ExchangeScenario: exchangeScenarioType } = exchangeScenario;

      let body, disclaimer, title;
      let eligible = false;
      let newModalState = ExchangeModalState.LoadFailed;

      switch (exchangeScenarioType) {
        case ExchangeScenario.airlineControl:
          ({ body, title } = (
            exchangeScenario as IAirlineControlExchangePolicy
          ).copy);

          modalSubtitleRef.current = body;
          modalTitleRef.current = title;

          newModalState = ExchangeModalState.ContactAirline;
          break;
        case ExchangeScenario.canceled:
          modalSubtitleRef.current = copy.FLIGHT_CANCELLED_SUBTITLE;
          modalTitleRef.current = copy.FLIGHT_CANCELLED_TITLE;

          newModalState = ExchangeModalState.IneligibleForExchange;
          break;
        case ExchangeScenario.ftc:
          const { Ftc } = exchangeScenario as IFTCExchangePolicy;

          switch (Ftc) {
            case FTCScenario.available:
              eligible = true;
              goToExchange();
              break;
            case FTCScenario.expired:
            case FTCScenario.redeemed:
            default:
              newModalState = ExchangeModalState.LoadFailed;
          }
          break;
        case ExchangeScenario.exchangeable:
          eligible = true;
          goToExchange();
          break;

        case ExchangeScenario.departed:
        case ExchangeScenario.nonExchangeable:
          ({ body, title } = (
            exchangeScenario as INonExchangeableRes
          ).exchangeCopy);

          modalSubtitleRef.current = body;
          modalTitleRef.current = title;

          newModalState = ExchangeModalState.ContactAirline;
          break;

        case ExchangeScenario.contactAirline:
          ({ body, disclaimer, title } = (
            exchangeScenario as IContactAirlineRes
          ).exchangeCopy);

          modalDisclaimerRef.current = disclaimer ?? "";
          modalSubtitleRef.current = body;
          modalTitleRef.current = title;

          newModalState = ExchangeModalState.ContactAirline;
          break;

        case ExchangeScenario.bookingPending:
        case ExchangeScenario.cancellationPending:
        case ExchangeScenario.containsRemarks:
        case ExchangeScenario.contactCustomerService:
          ({ body, title } = (
            exchangeScenario as IContactCustomerServiceRes
          ).customerServiceCopy);

          modalSubtitleRef.current = body;
          modalTitleRef.current = title;

          newModalState = ExchangeModalState.ContactSupport;
          break;

        case ExchangeScenario.nonChfar:
          const { NonChfar } = exchangeScenario as INonChfarExchangePolicy;

          switch (NonChfar) {
            case NonChfarScenario.contactAirline:
              ({ body, disclaimer, title } = (
                exchangeScenario as INonChfarContactAirlineRes
              ).exchangeCopy);

              modalDisclaimerRef.current = disclaimer ?? "";
              modalSubtitleRef.current = body;
              modalTitleRef.current = title;

              newModalState = ExchangeModalState.ContactAirline;
              break;
            case NonChfarScenario.contactCustomerService:
              ({ body, title } = (
                exchangeScenario as INonChfarContactCustomerServiceRes
              ).customerServiceCopy);

              modalSubtitleRef.current = body;
              modalTitleRef.current = title;

              newModalState = ExchangeModalState.ContactSupport;
              break;
            case NonChfarScenario.exchangeable:
              eligible = true;
              goToExchange();
              break;
            case NonChfarScenario.multiProvider:
              eligible = true;
              goToExchange();
              break;
            case NonChfarScenario.nonExchangeable:
              ({ body, title } = (
                exchangeScenario as INonChfarNonExchangeableRes
              ).exchangeCopy);

              modalSubtitleRef.current = body;
              modalTitleRef.current = title;

              newModalState = ExchangeModalState.ContactAirline;
              break;
            case NonChfarScenario.ticketedVoidable:
            case NonChfarScenario.preTicketVoidable:
              eligible = (exchangeScenario as INonChfarTicketedVoidableRes)
                .isExchangeable;
              newModalState = ExchangeModalState.LegacyTicketedVoidable;
              break;
            default:
              newModalState = ExchangeModalState.LoadFailed;
          }
          break;
        default:
          newModalState = ExchangeModalState.LoadFailed;
      }

      if (voidScenario) {
        const { VoidScenario: voidScenarioType } = voidScenario;
        switch (voidScenarioType) {
          case VoidScenario.ticketedVoid:
          case VoidScenario.preTicketVoidable:
            newModalState = ExchangeModalState.TicketedVoidable;
            break;
          // If not voidable we will use the newModalState for exchange set above
          case VoidScenario.notVoidable:
            break;
          default:
            newModalState = ExchangeModalState.LoadFailed;
        }
      }

      setModalState(newModalState);

      if (!eligible) {
        const { ExchangeScenario: scenario } = exchangeScenario;
        const isFTC = scenario === ExchangeScenario.ftc;
        const event: TrackEventRequest = {
          eventName: "",
          properties: {
            eligibility_response: scenario,
          },
        };

        if (isFTC) {
          event.eventName = SelfServeEvents.FTCExchangeNotEligible;
          event.properties.ftc_response = exchangeScenario.Ftc;
        } else {
          // scenario is either canceled or nonchfar
          event.eventName = SelfServeEvents.ExchangeNotEligible;
          event.properties.nonchfar_response =
            "NonChfar" in exchangeScenario
              ? exchangeScenario.NonChfar
              : undefined;
        }
      }
    }
  }, []);

  const renderContactAirlineModal = () => {
    const { airlines = [] } = voidAndExchangePolicyRef.current as any;
    const actionLinks = airlines.map((airlineInfo: Airline) => {
      const {
        displayName,
        // phone,
        webLinks: { changeCancelPolicy, homePage },
      } = airlineInfo;
      const airlineWebsite = changeCancelPolicy ?? homePage;

      return (
        <ActionLink
          className="b2b"
          content={
            <>
              {buttonText.CONTACT_AIRLINE(displayName)}
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </>
          }
          onClick={() => {
            window.open(airlineWebsite, "_target")?.focus();
            closeModal();
          }}
        />
      );
    });

    return (
      <GenericModalContent
        actions={actionLinks}
        image={<Icon className="error-icon" name={IconName.ErrorState} />}
        subtitle={modalSubtitleRef.current}
        title={modalTitleRef.current}
      />
    );
  };

  const renderContactSupportModal = () => (
    <GenericModalContent
      actions={
        <ActionButton
          defaultStyle="h4r-primary"
          message={buttonText.CONTACT_SUPPORT}
          onClick={openSupportInNewTab}
        />
      }
      className="itin-not-found-modal-content modal-content"
      image={<Icon name={IconName.ErrorState} />}
      subtitle={modalSubtitleRef.current}
      title={modalTitleRef.current}
    />
  );

  const renderIneligibleForExchangeModal = () => (
    <GenericModalContent
      actions={
        <ActionButton
          defaultStyle="h4r-primary"
          message={buttonText.CLOSE}
          onClick={closeModal}
        />
      }
      className="ineligible-for-exchange-modal-content modal-content"
      image={<Icon name={IconName.UnableToProcess} />}
      subtitle={modalSubtitleRef.current}
      title={modalTitleRef.current}
    />
  );

  const renderItineraryNotFoundModal = () => (
    <GenericModalContent
      actions={null}
      className="itin-not-found-modal-content modal-content"
      image={<Icon name={IconName.UnableToProcess} />}
      subtitle={copy.ITINERARY_NOT_FOUND_SUBTITLE}
      title={copy.ITINERARY_NOT_FOUND_TITLE}
    />
  );

  const renderLoadFailedModal = () => {
    if (isCorpTenant(config.TENANT)) {
      trackEvent({
        eventName: MODAL_ALERT,
        properties: corpErrorModalEventProperties,
      });
    }
    return (
      <GenericModalContent
        actions={
          !isCorpTenant(config.TENANT) && (
            <>
              <ActionButton
                defaultStyle="h4r-primary"
                message={buttonText.TRY_AGAIN}
                onClick={getExchangePolicy}
              />
              <ActionButton
                defaultStyle="h4r-secondary"
                message={buttonText.CONTACT_SUPPORT}
                onClick={openSupportInNewTab}
              />
            </>
          )
        }
        className="load-failed-modal-content modal-content"
        image={<Icon name={IconName.UnableToProcess} />}
        subtitle={
          <>
            {copy.LOAD_FAILED_SUBTITLE(config.TENANT)}
            {isCorpTenant(config.TENANT) && (
              <>
                <br />
                <Typography
                  component="span"
                  color="inherit"
                  style={{ fontWeight: 400, fontSize: "20px" }}
                >
                  {CONTACT_SUPPORT_NUMBER}
                </Typography>
              </>
            )}
          </>
        }
        title={copy.LOAD_FAILED_TITLE}
      />
    );
  };

  const renderLoadingModal = () => (
    <B2BSpinnerWithText subtitle={copy.LOADING} />
  );

  const renderLegacyTicketedVoidableModal = () => {
    const { isExchangeable, voidableExchangeCopy } = voidAndExchangePolicyRef
      .current?.exchangeScenario as INonChfarTicketedVoidableRes;

    return (
      <GenericModalContent
        actions={
          <>
            <ActionButton
              className="voidable-cancel-cta"
              defaultStyle="h4r-primary"
              message={buttonText.VOIDABLE}
              onClick={goToSelfServeFlightCancel}
            />
            {isExchangeable && (
              <ActionButton
                className="voidable-change-cta"
                defaultStyle="h4r-secondary"
                message={buttonText.CHANGE_FLIGHT}
                onClick={goToExchange}
              />
            )}
          </>
        }
        className="ticketed-voidable-modal-content modal-content"
        image={<Icon aria-hidden name={IconName.UnableToProcess} />}
        subtitle={voidableExchangeCopy.body}
        title={voidableExchangeCopy.title}
      />
    );
  };

  const renderTicketedVoidableModal = () => {
    const { voidableExchangeCopy } = voidAndExchangePolicyRef.current
      ?.voidScenario as ITicketedVoidableRes;

    const isExchangeable =
      voidAndExchangePolicyRef.current?.exchangeScenario.ExchangeScenario ===
      ExchangeScenario.exchangeable;

    return (
      <GenericModalContent
        actions={
          <>
            <ActionButton
              className="voidable-cancel-cta"
              defaultStyle="h4r-primary"
              message={buttonText.VOIDABLE}
              onClick={goToSelfServeFlightCancel}
            />
            {isExchangeable && (
              <ActionButton
                className="voidable-change-cta"
                defaultStyle="h4r-secondary"
                message={buttonText.CHANGE_FLIGHT}
                onClick={goToExchange}
              />
            )}
          </>
        }
        className="ticketed-voidable-modal-content modal-content"
        image={<Icon aria-hidden name={IconName.UnableToProcess} />}
        subtitle={voidableExchangeCopy.body}
        title={voidableExchangeCopy.title}
      />
    );
  };

  const ModalContent = useMemo(() => {
    switch (modalState) {
      case ExchangeModalState.ContactAirline:
        return renderContactAirlineModal();
      case ExchangeModalState.ContactSupport:
        return renderContactSupportModal();
      case ExchangeModalState.IneligibleForExchange:
        return renderIneligibleForExchangeModal();
      case ExchangeModalState.ItineraryNotFound:
        return renderItineraryNotFoundModal();
      case ExchangeModalState.LoadFailed:
        return renderLoadFailedModal();
      case ExchangeModalState.LoadingOrProcessing:
        return renderLoadingModal();
      case ExchangeModalState.LegacyTicketedVoidable:
        return renderLegacyTicketedVoidableModal();
      case ExchangeModalState.TicketedVoidable:
        return renderTicketedVoidableModal();
      default:
        return null;
    }
  }, [modalState]);

  useEffect(() => {
    getExchangePolicy();
    trackEvent({
      eventName: SelfServeEvents.ViewedExchangeModal,
      properties: viewedModalProperties,
    });
  }, [getExchangePolicy]);

  return <Box className="exchange-flight-modal-content">{ModalContent}</Box>;
};

ExchangeFlightModalContent.defaultProps = defaultProps;

export default withRouter(ExchangeFlightModalContent);
