import { createSelector } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import {
  BookedFlightItineraryWithDepartureTime,
  CallState,
  HotelItinerary,
  MyTripsFilter,
  ItineraryWithType,
  ItineraryEnum,
  Itinerary,
  getDepartureSlice,
  getReturnSlice,
  CarReservation,
  ViewedContactModalProperties,
  FlightItinerarySlice,
  MyTripsProperties,
  UserActionScheduleChangeSuccessProperties,
  FlightProvider,
  PortalItineraryStatusEnum,
  Lodging,
  HotelEntryProperties,
  ITrackingProperties,
  ViewedHotelListProperties,
  HotelCfarCancelScenarioEnum,
  HotelCfarRefundable,
  FlightPriceFreezeWithType,
  HotelPriceFreezeWithType,
  PriceFreezeWithType,
  HotelPriceFreezeStateEnum,
  PriceFreezeStatusEnum,
  StatementCreditDetail,
  LodgingCollectionEnum,
  CfarEnum,
  HomesItinerary,
  TravelItineraryEnum,
  Airline,
  Airport,
  PendingFlightItinerary,
  PendingGroundItinerary,
  PendingHotelItinerary,
  WatchAlertView,
  Person,
  LodgingData,
  HomesPriceQuoteListing,
  PackageItinerary,
} from "redmond";

import { TF_PREFIX } from "../../components/ItineraryList/components/ItinerariesModal/components/SelfServeCancelFlightModalContent/constants";
import { IStoreState } from "../../../../reducers/types";
import { getAirlineWebLink, isExpiredAndPastTripDate } from "../../utils";
import {
  getAgentEmail,
  getIsFirstLaunch,
  getRewardsAccounts,
  getSelectedAccount,
  getSelectedAccountIndex,
} from "../../../../modules/rewards/reducer";
import {
  getMyTripsTravelWalletCredit,
  getMyTripsTravelWalletCreditBreakdown,
} from "../../../../modules/travel-wallet/reducer";
import {
  AVAILABLE,
  ActiveExperiments,
  CONTROL,
  HOTEL_CFAR_REFUND_DISPLAY_80_PERCENT_REFUND_AMOUNT,
  HOTEL_CFAR_REFUND_DISPLAY_NON_REF_PARTIAL,
  HOTEL_CFAR_REFUND_DISPLAY_REFUND_DOLLAR_AMOUNT_ADDITIONAL_PLACES,
} from "../../../../context/experiments";

const sortByDate = (a?: string, b?: string) => {
  const aDiff = dayjs(a).diff(dayjs());
  const bDiff = dayjs(b).diff(dayjs());
  return aDiff - bDiff;
};

const sortByDescendingDate = (a?: string, b?: string) => {
  const aDiff = dayjs(a).diff(dayjs());
  const bDiff = dayjs(b).diff(dayjs());
  return bDiff - aDiff;
};

export const getAreAllFlightFetchingsNotCalled = (state: IStoreState) =>
  Object.values(state.tripsList.fetchFlightsCallState).every(
    (callState) => callState === CallState.NotCalled
  );

export const getAreAllFlightFetchingsInTerminalState = (state: IStoreState) =>
  Object.values(state.tripsList.fetchFlightsCallState).every(
    (callState) =>
      callState === CallState.Failed || callState === CallState.Success
  );

export const getAreSomeFlightFetchingsInProcess = (state: IStoreState) =>
  Object.values(state.tripsList.fetchFlightsCallState).includes(
    CallState.InProcess
  );

export const getAreTripsLoading = (state: IStoreState) =>
  Object.values(state.tripsList.fetchFlightsCallState).includes(
    CallState.InProcess
  ) ||
  Object.values(state.tripsList.fetchHotelsCallState).includes(
    CallState.InProcess
  ) ||
  Object.values(state.tripsList.fetchCarsCallState).includes(
    CallState.InProcess
  ) ||
  state.tripsList.listPriceFreezeCallState === CallState.InProcess ||
  state.tripsList.listHotelPriceFreezeCallState === CallState.InProcess ||
  state.tripsList.listWatchCallState === CallState.InProcess;

export const getListWatchesCallState = (state: IStoreState) =>
  state.tripsList.listWatchCallState;

export const getFetchFlightsCallState = (state: IStoreState) =>
  state.tripsList.fetchFlightsCallState;

export const getFetchHotelsCallState = (state: IStoreState) =>
  state.tripsList.fetchHotelsCallState;

export const getFetchCarsCallState = (state: IStoreState) =>
  state.tripsList.fetchCarsCallState;

export const getListPriceFreezeCallState = (state: IStoreState) =>
  state.tripsList.listPriceFreezeCallState;

export const getListHotelPriceFreezeCallState = (state: IStoreState) =>
  state.tripsList.listHotelPriceFreezeCallState;

export const getAcceptScheduleChangeCallState = (state: IStoreState) =>
  state.tripsList.acceptScheduleChangeCallState;

export const getDenyScheduleChangeCallState = (state: IStoreState) =>
  state.tripsList.denyScheduleChangeCallState;

export const getAirlinesMap = (state: IStoreState) => state.tripsList.airlines;

export const getPriceFreezeAirlinesMap = (state: IStoreState) =>
  state.tripsList.priceFreezeAirlines;

export const getAirportMap = (state: IStoreState) => state.tripsList.airports;

export const getPriceFreezeAirportMap = (state: IStoreState) =>
  state.tripsList.priceFreezeAirports;

export const getSelectedFlight = (state: IStoreState) =>
  state.tripsList.selectedFlight;

export const getTripsFilter = (state: IStoreState) =>
  state.tripsList.tripsFilter;

export const getFlights = (state: IStoreState) => state.tripsList.itineraries;

export const getSelectedHotel = (state: IStoreState) =>
  state.tripsList.selectedHotel;

export const getHotels = (state: IStoreState) =>
  state.tripsList.hotelItineraries;

export const getSelectedHome = (state: IStoreState) =>
  state.tripsList.selectedHome;

export const getHomes = (state: IStoreState) => state.tripsList.homeItineraries;

export const getSelectedCar = (state: IStoreState) =>
  state.tripsList.selectedCar;

export const getCars = (state: IStoreState) => state.tripsList.carItineraries;

export const getPackages = (state: IStoreState) =>
  state.tripsList.packageItineraries;

export const getSelectedPackage = (state: IStoreState) =>
  state.tripsList.selectedPackage;

export const getSelectedPackageHotel = (state: IStoreState) =>
  state.tripsList.selectedPackageHotel;

export const getSelectedPackageFlight = (state: IStoreState) =>
  state.tripsList.selectedPackageFlight;

export const getWatches = (state: IStoreState) => state.tripsList.watches;

export const getFlightPriceFreezes = (state: IStoreState) =>
  state.tripsList.priceFreezes;

export const getHotelPriceFreezes = (state: IStoreState) =>
  state.tripsList.hotelPriceFreezes;

export const getWatchContext = (state: IStoreState) =>
  state.tripsList.watchContext;

export const getSelectedFlightCfarInfo = (state: IStoreState) =>
  state.tripsList.selectedCfar?.value;

export const getTripSearchQuery = (state: IStoreState) =>
  state.tripsList.searchQuery;

export const getIsMultiCityCfar = createSelector(
  getSelectedFlightCfarInfo,
  (selectedCfar) => {
    return (
      (selectedCfar?.Cfar != CfarEnum.CancellationPending &&
        selectedCfar?.Cfar != CfarEnum.ContactCustomerService &&
        selectedCfar?.Cfar != CfarEnum.TooCloseToDeparture &&
        selectedCfar?.isMultiCity) ??
      false
    );
  }
);

export const getSelectedFlightCfarItineraryId = (state: IStoreState) =>
  state.tripsList.selectedCfarItineraryId;

export const getFetchFlightCfarInfoCallState = (state: IStoreState) =>
  state.tripsList.fetchFlightCfarInfoCallState;

export const getFlightCfarCancellationResponse = (state: IStoreState) =>
  state.tripsList.flightCfarCancellationResponse;

export const getConfirmFlightCfarCancellationCallState = (state: IStoreState) =>
  state.tripsList.confirmFlightCfarCancellationCallState;

export const hotelCfarRefundExperimentVariantSelector = (state: IStoreState) =>
  state.tripsList.experiments?.[ActiveExperiments.HOTEL_CFAR_REFUND_DISPLAY];
export const isFintechCsatEnabledSelector = (state: IStoreState) =>
  state.tripsList.experiments?.[ActiveExperiments.FINTECH_CSAT] == AVAILABLE;

export const isHotelCfarRefundDisplayShowAmountAdditionalPlacesEnabledSelector =
  createSelector(
    hotelCfarRefundExperimentVariantSelector,
    (hotelCfarRefundExperimentVariant) =>
      hotelCfarRefundExperimentVariant ===
      HOTEL_CFAR_REFUND_DISPLAY_REFUND_DOLLAR_AMOUNT_ADDITIONAL_PLACES
  );

export const isHotelCfarRefundDisplay80PercentRefundAmountEnabledSelector =
  createSelector(
    hotelCfarRefundExperimentVariantSelector,
    (hotelCfarRefundExperimentVariant) =>
      hotelCfarRefundExperimentVariant ===
      HOTEL_CFAR_REFUND_DISPLAY_80_PERCENT_REFUND_AMOUNT
  );

export const isHotelCfarRefundDisplayNonRefPartialEnabledSelector =
  createSelector(
    hotelCfarRefundExperimentVariantSelector,
    (hotelCfarRefundExperimentVariant) =>
      hotelCfarRefundExperimentVariant ===
      HOTEL_CFAR_REFUND_DISPLAY_NON_REF_PARTIAL
  );

export const getHotelCfarCancellationPolicyCallState = (state: IStoreState) =>
  state.tripsList.hotelCfarCancellationPolicyCallState;

export const getHotelCfarCancellationPolicyScenario = (state: IStoreState) =>
  state.tripsList.hotelCfarCancellationPolicyScenario;

export const getHotelCfarCancellationStatus = createSelector(
  getHotelCfarCancellationPolicyScenario,
  (hotelCfarCancellationPolicyScenario) =>
    hotelCfarCancellationPolicyScenario?.HotelCfarCancelScenario
);

export const getHotelCfarCancellationPolicy = createSelector(
  getHotelCfarCancellationPolicyScenario,
  getHotelCfarCancellationStatus,
  (
    hotelCfarCancellationPolicyScenario,
    hotelCfarCancellationStatus
  ): HotelCfarRefundable | null => {
    if (
      hotelCfarCancellationStatus == HotelCfarCancelScenarioEnum.CfarRefundable
    ) {
      return hotelCfarCancellationPolicyScenario as HotelCfarRefundable;
    }
    return null;
  }
);

export const getSelectedHotelCfarReservationId = (state: IStoreState) =>
  state.tripsList.selectedHotelCfarReservationId;

export const getConfirmHotelCfarCancellationCallState = (state: IStoreState) =>
  state.tripsList.confirmHotelCfarCancellationCallState;

export const getTravelCredits = createSelector(
  [getFlights, getTripSearchQuery, getAirportMap, getAirlinesMap],
  (flights, searchQuery, airports, airlines) => {
    const { past = [], future = [], canceled = [], present = [] } = flights;
    return [...past, ...present, ...future, ...canceled].filter(
      (fl) =>
        matchSearchQuery(searchQuery, airports, airlines)(fl) &&
        !!fl.travelCredit &&
        fl.status === PortalItineraryStatusEnum.Canceled
    );
  }
);

export const getTripId = createSelector(
  [
    getSelectedFlight,
    getSelectedHotel,
    getSelectedCar,
    getSelectedHome,
    getSelectedPackageFlight,
    getSelectedPackageHotel,
  ],
  (
    selectedFlight,
    selectedHotel,
    selectedCar,
    selectedHome,
    selectedPackageFlight,
    selectedPackageHotel
  ) => {
    if (selectedFlight) {
      return selectedFlight.bookedItinerary.id;
    }
    if (selectedHotel) {
      return selectedHotel.reservation.reservationId;
    }
    if (selectedCar) {
      return selectedCar.bookResult.groundBookingId;
    }
    if (selectedHome) {
      return selectedHome.reservation.id.value;
    }
    if (selectedPackageHotel) {
      return selectedPackageHotel.reservation.reservationId;
    }
    if (selectedPackageFlight) {
      return selectedPackageFlight.bookedItinerary.id;
    }
    return "";
  }
);

const getDepartureDateFromFlight = (
  flight: BookedFlightItineraryWithDepartureTime
) => getDepartureSlice(flight.bookedItinerary).segments[0]?.scheduledDeparture;

const getReturnDateFromFlight = (
  flight: BookedFlightItineraryWithDepartureTime
) =>
  getReturnSlice(flight.bookedItinerary)?.segments[0]?.scheduledArrival || "";

const getDateFromHotel = (hotel: HotelItinerary) =>
  hotel.reservation.checkInDate;

const getDateFromHome = (home: HomesItinerary) =>
  home.reservation.stayDates.from;

const getDateFromCar = (car: CarReservation) => car.bookResult.pickUp.dateTime;

const getDropOffDateFromCar = (car: CarReservation) =>
  car.bookResult.dropOff.dateTime;

const getDateFromItinerary = (itinerary: PackageItinerary) =>
  getDepartureSlice(itinerary.flight.itinerary.bookedItinerary)?.segments[0]
    .scheduledArrival || "";

const getInitialDateForItinerary = (itinerary: ItineraryWithType) => {
  switch (itinerary.type) {
    case ItineraryEnum.Flight:
      return getDepartureDateFromFlight(
        itinerary as BookedFlightItineraryWithDepartureTime
      );
    case ItineraryEnum.Hotel:
      return getDateFromHotel(itinerary as HotelItinerary);
    case ItineraryEnum.Car:
      return getDateFromCar(itinerary as CarReservation);
    case ItineraryEnum.Home:
      return getDateFromHome(itinerary as HomesItinerary);
    case ItineraryEnum.Package:
      return getDateFromItinerary(itinerary as PackageItinerary);
    default:
      return undefined;
  }
};

const sortItinerariesByDate = (
  a: ItineraryWithType,
  b: ItineraryWithType,
  isPast?: boolean
) => {
  let aDate = getInitialDateForItinerary(a);
  let bDate = getInitialDateForItinerary(b);

  const getDepDiff = (dateStringX?: string, dateStringY?: string) =>
    isPast
      ? sortByDescendingDate(dateStringX, dateStringY)
      : sortByDate(dateStringX, dateStringY);

  const depDiff = getDepDiff(aDate, bDate);
  if (depDiff === 0) {
    let aReturn;
    let bReturn;

    if (a.type === ItineraryEnum.Flight && b.type === ItineraryEnum.Flight) {
      aReturn = getReturnDateFromFlight(
        a as BookedFlightItineraryWithDepartureTime
      );
      bReturn = getReturnDateFromFlight(
        b as BookedFlightItineraryWithDepartureTime
      );
      aDate = aReturn || aDate;
      bDate = bReturn || bDate;
      return getDepDiff(aDate, bDate);
    }
    if (a.type === ItineraryEnum.Car && b.type === ItineraryEnum.Car) {
      aReturn = getDropOffDateFromCar(a as CarReservation);
      bReturn = getDropOffDateFromCar(b as CarReservation);
      aDate = aReturn || aDate;
      bDate = bReturn || bDate;
      return getDepDiff(aDate, bDate);
    }
  }
  return depDiff;
};

const addTypeToItinerary = (
  itineraries: Itinerary[],
  type: ItineraryEnum
): ItineraryWithType[] =>
  itineraries.map((itinerary) => ({ ...itinerary, type }));

const filterItineraries: (
  tripsFilter: MyTripsFilter,
  hotels: { [key: string]: HotelItinerary[] },
  flights: { [key: string]: BookedFlightItineraryWithDepartureTime[] },
  cars: { [key: string]: CarReservation[] },
  homes: { [key: string]: HomesItinerary[] },
  packages: { [key: string]: PackageItinerary[] }
) => {
  hotels: HotelItinerary[];
  flights: BookedFlightItineraryWithDepartureTime[];
  cars: CarReservation[];
  homes: HomesItinerary[];
  packages: PackageItinerary[];
  isPast: boolean;
} = (tripsFilter, hotels, flights, cars, homes, packages) => {
  const {
    past: pastHotels = [],
    future: futureHotels = [],
    present: presentHotels = [],
    canceled: cancelledHotels = [],
  } = hotels;
  const {
    past: pastFlights = [],
    future: futureFlights = [],
    present: presentFlights = [],
    canceled: canceledFlights = [],
  } = flights;
  const {
    past: pastCars = [],
    future: futureCars = [],
    present: presentCars = [],
    canceled: canceledCars = [],
  } = cars;
  const {
    past: pastHomes = [],
    future: futureHomes = [],
    present: presentHomes = [],
    canceled: canceledHomes = [],
  } = homes;
  const {
    past: pastPackages = [],
    future: futurePackages = [],
    present: presentPackages = [],
    canceled: canceledPackages = [],
  } = packages;
  const canceledPastFlights = canceledFlights.filter((flight) => {
    const dateDiff = dayjs(
      getReturnDateFromFlight(flight) || getDepartureDateFromFlight(flight)
    ).diff(dayjs());
    return dateDiff <= 0;
  });

  const canceledFutureFlights = canceledFlights.filter((flight) => {
    const dateDiff = dayjs(
      getReturnDateFromFlight(flight) || getDepartureDateFromFlight(flight)
    ).diff(dayjs());
    return dateDiff > 0;
  });

  const canceledPastCars = canceledCars.filter((car) => {
    const dateDiff = dayjs(
      car.bookResult.pickUp.dateTime || car.bookResult.dropOff.dateTime
    ).diff(dayjs());
    return dateDiff <= 0;
  });

  const canceledFutureCars = canceledCars.filter((car) => {
    const dateDiff = dayjs(
      car.bookResult.pickUp.dateTime || car.bookResult.dropOff.dateTime
    ).diff(dayjs());
    return dateDiff > 0;
  });

  if (tripsFilter === MyTripsFilter.UPCOMING_TRIPS) {
    return {
      cars: [...futureCars, ...presentCars],
      flights: [...futureFlights, ...presentFlights],
      hotels: [...futureHotels, ...presentHotels],
      homes: [...futureHomes, ...presentHomes],
      packages: [...futurePackages, ...presentPackages],
      isPast: false,
    };
  }
  if (tripsFilter === MyTripsFilter.PAST_TRIPS) {
    return {
      cars: [...canceledFutureCars, ...canceledPastCars, ...pastCars],
      flights: [
        ...canceledFutureFlights,
        ...canceledPastFlights,
        ...pastFlights,
      ],
      hotels: [...cancelledHotels, ...pastHotels],
      homes: [...canceledHomes, ...pastHomes],
      packages: [...canceledPackages, ...pastPackages],
      isPast: true,
    };
  }
  return {
    cars: [],
    flights: [],
    hotels: [],
    homes: [],
    packages: [],
    isPast: false,
  };
};

const combineItineraries = (itineraries: {
  hotels: HotelItinerary[];
  flights: BookedFlightItineraryWithDepartureTime[];
  cars: CarReservation[];
  homes: HomesItinerary[];
  packages: PackageItinerary[];
  isPast: boolean;
}) =>
  [
    ...addTypeToItinerary(itineraries.cars, ItineraryEnum.Car),
    ...addTypeToItinerary(itineraries.flights, ItineraryEnum.Flight),
    ...addTypeToItinerary(itineraries.hotels, ItineraryEnum.Hotel),
    ...addTypeToItinerary(itineraries.homes, ItineraryEnum.Home),
    ...addTypeToItinerary(itineraries.packages, ItineraryEnum.Package),
  ].sort((a, b) => sortItinerariesByDate(a, b, itineraries.isPast));

export const upcomingItinerariesSelector = createSelector(
  [getHotels, getFlights, getCars, getHomes, getPackages],
  (hotels, flights, cars, homes, packages) =>
    filterItineraries(
      MyTripsFilter.UPCOMING_TRIPS,
      hotels,
      flights,
      cars,
      homes,
      packages
    )
);

export const pastItinerariesSelector = createSelector(
  [getHotels, getFlights, getCars, getHomes, getPackages],
  (hotels, flights, cars, homes, packages) =>
    filterItineraries(
      MyTripsFilter.PAST_TRIPS,
      hotels,
      flights,
      cars,
      homes,
      packages
    )
);

export const activeCfarCountSelector = createSelector(
  [upcomingItinerariesSelector],
  (upcomingItineraries) =>
    upcomingItineraries.flights.filter((f) => !!f.ancillaries.cfar).length
);

export const itinerariesToDisplaySelector = createSelector(
  [
    getTripsFilter,
    getHotels,
    getFlights,
    getCars,
    getHomes,
    getPackages,
    getTripSearchQuery,
    getAirportMap,
    getAirlinesMap,
  ],
  (
    tripsFilter,
    hotels,
    flights,
    cars,
    homes,
    packages,
    searchQuery,
    airports,
    airlines
  ) =>
    combineItineraries(
      filterItineraries(tripsFilter, hotels, flights, cars, homes, packages)
    ).filter(matchSearchQuery(searchQuery, airports, airlines))
);

export const flightPriceFreezeListSortedSelector = createSelector(
  [getTripsFilter, getFlightPriceFreezes],
  (tripsFilter, pf) => {
    if (tripsFilter === MyTripsFilter.PRIZE_FREEZES && Array.isArray(pf)) {
      return pf.sort((a, b) =>
        sortByDate(
          a.tripDetails.slices[0].departureTime,
          b.tripDetails.slices[0].departureTime
        )
      );
    }
    return [];
  }
);

export const combinedPriceFreezesSelector = createSelector(
  [getFlightPriceFreezes, getHotelPriceFreezes],
  (flightPriceFreezes, hotelPriceFreezes) => {
    const flightPriceFreezesWithTypeMarker: FlightPriceFreezeWithType[] =
      flightPriceFreezes.map((pf) => ({ type: "flight", priceFreeze: pf }));

    // note: https://hopchat.slack.com/archives/C04DLEXUN87/p1685113914104539?thread_ts=1684957357.642729&cid=C04DLEXUN87
    const acceptedStates = [
      HotelPriceFreezeStateEnum.Valid,
      HotelPriceFreezeStateEnum.Expired,
    ];
    const hotelPriceFreezesWithTypeMarker: HotelPriceFreezeWithType[] =
      hotelPriceFreezes.flatMap((pf) =>
        acceptedStates.includes(pf.voucher.state.State)
          ? {
              type: "hotel",
              priceFreeze: pf,
            }
          : []
      );

    const combinedList: PriceFreezeWithType[] = [
      ...flightPriceFreezesWithTypeMarker,
      ...hotelPriceFreezesWithTypeMarker,
    ];

    return combinedList;
  }
);

export const notPastTripEndDateCombinedPriceFreezesSelector = createSelector(
  [combinedPriceFreezesSelector],
  (combinedPriceFreezes) => {
    return combinedPriceFreezes.filter((pf) => {
      return !isExpiredAndPastTripDate(pf);
    });
  }
);

export const notPastTripEndDateCombinedPriceFreezesSortedSelector =
  createSelector(
    [getTripsFilter, notPastTripEndDateCombinedPriceFreezesSelector],
    (tripsFilter, combinedPriceFreezes) => {
      if (
        tripsFilter === MyTripsFilter.PRIZE_FREEZES &&
        Array.isArray(combinedPriceFreezes)
      ) {
        const getDepartureTime = (pf: PriceFreezeWithType) => {
          return pf.type === "flight"
            ? pf.priceFreeze.tripDetails.slices[0].departureTime
            : pf.priceFreeze.voucher.reservation.start;
        };

        return combinedPriceFreezes.sort((a, b) => {
          const departureTimeA = getDepartureTime(a);
          const departureTimeB = getDepartureTime(b);

          return sortByDate(departureTimeA, departureTimeB);
        });
      }

      return [];
    }
  );

export const getPriceFreezeCounts = createSelector(
  [notPastTripEndDateCombinedPriceFreezesSortedSelector],
  (notPastTripEndDateCombinedPriceFreezesSorted) => {
    const flightPriceFreezes = notPastTripEndDateCombinedPriceFreezesSorted
      .filter(
        (
          priceFreezeWithType
        ): priceFreezeWithType is FlightPriceFreezeWithType =>
          priceFreezeWithType.type === "flight"
      )
      .map((priceFreezeWithType) => priceFreezeWithType.priceFreeze);

    const activeFlightPriceFreezeCount = flightPriceFreezes.filter(
      (pf) => pf.status.Status === PriceFreezeStatusEnum.IsActive
    ).length;
    const expiredFlightPriceFreezeCount =
      flightPriceFreezes.length - activeFlightPriceFreezeCount;

    const hotelPriceFreezes = notPastTripEndDateCombinedPriceFreezesSorted
      .filter(
        (
          priceFreezeWithType
        ): priceFreezeWithType is HotelPriceFreezeWithType =>
          priceFreezeWithType.type === "hotel"
      )
      .map((priceFreezeWithType) => priceFreezeWithType.priceFreeze);

    const activeHotelPriceFreezeCount = hotelPriceFreezes.filter(
      (pf) => pf.voucher.state.State === HotelPriceFreezeStateEnum.Valid
    ).length;

    const expiredHotelPriceFreezeCount =
      hotelPriceFreezes.length - activeHotelPriceFreezeCount;

    return {
      flightActive: activeFlightPriceFreezeCount,
      flightExpired: expiredFlightPriceFreezeCount,
      hotelActive: activeHotelPriceFreezeCount,
      hotelExpired: expiredHotelPriceFreezeCount,
    };
  }
);

export const getTripRequests = (state: IStoreState) =>
  state.tripsList.tripRequests;

export const pendingItinerariesToDisplaySelector = createSelector(
  [getTripRequests, getTripSearchQuery, getAirportMap, getAirlinesMap],
  (pendingRequests, searchQuery, airports, airlines) => {
    const { flights, hotels, cars } = pendingRequests;

    const merged = [...flights, ...hotels, ...cars];

    return merged
      .filter(matchSearchQuery(searchQuery, airports, airlines))
      .sort((a, b) =>
        sortByDescendingDate(
          a.approvalInfo.requestedAt,
          b.approvalInfo.requestedAt
        )
      );
  }
);

export const getTripsCounts = createSelector(
  [
    getHotels,
    getFlights,
    getCars,
    getHomes,
    getPackages,
    getWatches,
    // Count for PF doesn't include expired ones that are already past the trip end date.
    notPastTripEndDateCombinedPriceFreezesSelector,
    getTravelCredits,
    getTripRequests,
  ],
  (
    hotels,
    flights,
    cars,
    homes,
    packages,
    watches,
    priceFreezes,
    travelCredits,
    tripRequests
  ) => {
    const {
      past: pastHotels = [],
      future: futureHotels = [],
      present: presentHotels = [],
      canceled: cancelledHotels = [],
    } = hotels;
    const {
      past: pastFlights = [],
      future: futureFlights = [],
      present: presentFlights = [],
      canceled: canceledFlights = [],
    } = flights;
    const {
      past: pastCars = [],
      future: futureCars = [],
      present: presentCars = [],
      canceled: canceledCars = [],
    } = cars;
    const {
      past: pastHomes = [],
      future: futureHomes = [],
      present: presentHomes = [],
      canceled: canceledHomes = [],
    } = homes;
    const {
      past: pastPackages = [],
      future: futurePackages = [],
      present: presentPackages = [],
      canceled: canceledPackages = [],
    } = packages;
    const canceledPastFlights = canceledFlights.filter((flight) => {
      const dateDiff = dayjs(
        getReturnDateFromFlight(flight) || getDepartureDateFromFlight(flight)
      ).diff(dayjs());
      return dateDiff <= 0;
    });

    const canceledFutureFlights = canceledFlights.filter((flight) => {
      const dateDiff = dayjs(
        getReturnDateFromFlight(flight) || getDepartureDateFromFlight(flight)
      ).diff(dayjs());
      return dateDiff > 0;
    });
    const canceledPastCars = canceledCars.filter((car) => {
      const dateDiff = dayjs(
        car.bookResult.pickUp.dateTime || car.bookResult.dropOff.dateTime
      ).diff(dayjs());
      return dateDiff <= 0;
    });

    const canceledFutureCars = canceledCars.filter((car) => {
      const dateDiff = dayjs(
        car.bookResult.pickUp.dateTime || car.bookResult.dropOff.dateTime
      ).diff(dayjs());
      return dateDiff > 0;
    });
    const totalUpcomingTrips =
      futureHotels.length +
      futureFlights.length +
      futureCars.length +
      futureHomes.length +
      futurePackages.length +
      presentHotels.length +
      presentFlights.length +
      presentCars.length +
      presentHomes.length +
      presentPackages.length;
    const totalPastTrips =
      canceledFutureFlights.length +
      canceledFutureCars.length +
      canceledHomes.length +
      canceledPackages.length +
      pastHotels.length +
      pastFlights.length +
      pastCars.length +
      pastHomes.length +
      pastPackages.length;
    canceledPastFlights.length +
      cancelledHotels.length +
      canceledPastCars.length;

    const totalRequestedTrips =
      tripRequests.hotels.length +
      tripRequests.cars.length +
      tripRequests.flights.length;

    return {
      [MyTripsFilter.UPCOMING_TRIPS]: totalUpcomingTrips,
      [MyTripsFilter.PAST_TRIPS]: totalPastTrips,
      [MyTripsFilter.TRAVEL_CREDITS]: travelCredits?.length || 0,
      [MyTripsFilter.WATCHED_TRIPS]: watches?.length || 0,
      [MyTripsFilter.PRIZE_FREEZES]: priceFreezes.length,
      [MyTripsFilter.TRIP_REQUESTS]: totalRequestedTrips || 0,
    };
  }
);

export const watchesSelector = createSelector(
  [
    getTripsFilter,
    getWatches,
    getTripSearchQuery,
    getAirportMap,
    getAirlinesMap,
  ],
  (tripsFilter, watches, searchQuery, airports, airlines) => {
    if (tripsFilter === MyTripsFilter.WATCHED_TRIPS && Array.isArray(watches)) {
      return watches
        .filter(matchSearchQuery(searchQuery, airports, airlines))
        .sort((a, b) =>
          sortByDate(a.key.value.departureDate, b.key.value.departureDate)
        );
    }
    return [];
  }
);

export const getFetchUpcomingFlightsFailed = createSelector(
  getFetchFlightsCallState,
  (flightsCallState) =>
    flightsCallState.present === CallState.Failed ||
    flightsCallState.future === CallState.Failed
);

export const getFetchPastFlightsFailed = createSelector(
  getFetchFlightsCallState,
  (flightsCallState) =>
    flightsCallState.canceled === CallState.Failed ||
    flightsCallState.past === CallState.Failed
);

export const getFetchFlightsAllSuccess = createSelector(
  getFetchFlightsCallState,
  (flightsCallState) =>
    flightsCallState.canceled === CallState.Success ||
    flightsCallState.past === CallState.Success ||
    flightsCallState.present === CallState.Success ||
    flightsCallState.future === CallState.Success
);

export const getFetchFlightsAllFailed = createSelector(
  getFetchFlightsCallState,
  (fetchFlightsCallStates) =>
    fetchFlightsCallStates.canceled === CallState.Failed &&
    fetchFlightsCallStates.past === CallState.Failed &&
    fetchFlightsCallStates.present === CallState.Failed &&
    fetchFlightsCallStates.future === CallState.Failed
);

export const getFetchUpcomingHotelsFailed = createSelector(
  getFetchHotelsCallState,
  (hotelsCallState) =>
    hotelsCallState.present === CallState.Failed ||
    hotelsCallState.future === CallState.Failed
);

export const getFetchPastHotelsFailed = createSelector(
  getFetchHotelsCallState,
  (hotelsCallState) =>
    hotelsCallState.canceled === CallState.Failed ||
    hotelsCallState.present === CallState.Failed
);

export const getFetchUpcomingCarsFailed = createSelector(
  getFetchCarsCallState,
  (carsCallState) =>
    carsCallState.present === CallState.Failed ||
    carsCallState.future === CallState.Failed
);

export const getFetchPastCarsFailed = createSelector(
  getFetchCarsCallState,
  (carsCallState) =>
    carsCallState.canceled === CallState.Failed ||
    carsCallState.present === CallState.Failed
);

export const getFetchUpcomingTripsFailed = createSelector(
  getFetchUpcomingFlightsFailed,
  getFetchUpcomingHotelsFailed,
  getFetchUpcomingCarsFailed,
  (upcomingFlightsFailed, upcomingHotelsFailed, upcomingCarsFailed) =>
    upcomingFlightsFailed || upcomingHotelsFailed || upcomingCarsFailed
);

export const getFetchPastTripsFailed = createSelector(
  getFetchPastFlightsFailed,
  getFetchPastHotelsFailed,
  getFetchPastCarsFailed,
  (pastFlightsFailed, pastHotelsFailed, pastCarsFailed) =>
    pastFlightsFailed || pastHotelsFailed || pastCarsFailed
);

export const hasTripsToDisplay = createSelector(
  [
    getTripsFilter,
    getWatches,
    itinerariesToDisplaySelector,
    combinedPriceFreezesSelector,
    upcomingItinerariesSelector,
    pastItinerariesSelector,
    getTravelCredits,
    getTripRequests,
    getTripSearchQuery,
    getAirportMap,
    getAirlinesMap,
  ],
  (
    tripsFilter,
    watches,
    itineraries,
    pf,
    upcomingItineraries,
    pastItineraries,
    travelCredits,
    tripRequests,
    searchQuery,
    airports,
    airlines
  ) => {
    switch (tripsFilter) {
      case MyTripsFilter.UPCOMING_TRIPS:
        return (
          itineraries.length > 0 &&
          combineItineraries(upcomingItineraries).filter(
            matchSearchQuery(searchQuery, airports, airlines)
          ).length > 0
        );
      case MyTripsFilter.PAST_TRIPS:
        return (
          itineraries.length > 0 &&
          combineItineraries(pastItineraries).filter(
            matchSearchQuery(searchQuery, airports, airlines)
          ).length > 0
        );
      case MyTripsFilter.WATCHED_TRIPS:
        return watches.length > 0;
      case MyTripsFilter.TRAVEL_CREDITS:
        return travelCredits.length > 0;
      case MyTripsFilter.PRIZE_FREEZES:
        return pf.length > 0;
      case MyTripsFilter.TRIP_REQUESTS:
        return (
          Object.values(tripRequests)
            .flat()
            .filter(matchSearchQuery(searchQuery, airports, airlines)).length >
          0
        );
      default:
        return false;
    }
  }
);

export const getOpenModal = (state: IStoreState) => state.tripsList.openModal;
export const getRequestPassengerEdit = (state: IStoreState) =>
  state.tripsList.requestPassengerEdit;

export const getDeleteWatchCallState = (state: IStoreState) =>
  state.tripsList.deleteWatchCallState;

export const getUpdateWatchCallState = (state: IStoreState) =>
  state.tripsList.updateWatchCallState;

export const hasFetchTripsError = createSelector(
  [
    getTripsFilter,
    getListWatchesCallState,
    getFetchUpcomingTripsFailed,
    getFetchPastTripsFailed,
    getListPriceFreezeCallState,
    getListHotelPriceFreezeCallState,
  ],
  (
    tripsFilter,
    listWatchCallState,
    upcomingTripFailed,
    pastTripsFailed,
    listPriceFreezeCallState,
    listHotelPriceFreezeCallState
  ) => {
    switch (tripsFilter) {
      case MyTripsFilter.PAST_TRIPS:
        return pastTripsFailed;
      case MyTripsFilter.UPCOMING_TRIPS:
        return upcomingTripFailed;
      case MyTripsFilter.TRAVEL_CREDITS:
        return upcomingTripFailed && pastTripsFailed;
      case MyTripsFilter.WATCHED_TRIPS:
        return listWatchCallState === CallState.Failed;
      case MyTripsFilter.PRIZE_FREEZES:
        return (
          listPriceFreezeCallState === CallState.Failed ||
          listHotelPriceFreezeCallState === CallState.Failed
        );
      default:
        return false;
    }
  }
);

export const getViewedCarContactModalProperties = (
  car: CarReservation,
  contactReason?: ViewedContactModalProperties["contact_reason"]
): ViewedContactModalProperties => ({
  agent_locator:
    car?.bookResult.confirmationInfo?.supplierBookingId ||
    car?.bookResult.confirmationInfo?.providerBookingId ||
    car?.bookResult.groundBookingId ||
    "",
  agent_locator_provider: "",
  days_until_departure: car
    ? `${dayjs().diff(dayjs(car?.bookResult.pickUp.dateTime), "days")} days`
    : "",
  booking_date: car ? dayjs(car?.bookResult.pickUp.dateTime).toISOString() : "",
  contact_reason: contactReason || "cancel_car",
  contact_provider_ground:
    car?.bookResult.contactSupportInfo?.displayPhone || "",
});

export const getViewedHotelContactModalProperties = (
  hotel: HotelItinerary,
  contactReason?: ViewedContactModalProperties["contact_reason"]
): ViewedContactModalProperties => ({
  agent_locator: hotel?.reservation.reservationId || "",
  agent_locator_provider: "",
  days_until_departure: hotel
    ? `${dayjs().diff(dayjs(hotel?.reservation.checkInDate), "days")} days`
    : "",
  booking_date: hotel
    ? dayjs(hotel?.reservation.checkInDate).toISOString()
    : "",
  contact_reason: contactReason || "cancel_hotel",
  contact_provider_hotel: hotel?.reservation.lodgingData.phone || "",
});

export const getViewedCancelFlightContactModalProperties = (
  flight: BookedFlightItineraryWithDepartureTime
): ViewedContactModalProperties => {
  const slice: FlightItinerarySlice | null = flight
    ? getDepartureSlice(flight.bookedItinerary)
    : null;
  return {
    agent_locator:
      flight?.bookedItinerary.travelItinerary.locators?.agent.unscopedValue ||
      "",
    agent_locator_provider:
      flight?.bookedItinerary.travelItinerary.locators?.agent.unscopedValue ||
      "",
    days_until_departure: flight
      ? `${dayjs().diff(dayjs(flight.departureTime), "days")} days`
      : "",
    booking_date: flight?.departureTime || "",
    contact_reason: "cancel_flight",
    contact_provider_air:
      slice && slice?.segments.length > 0
        ? slice?.segments[0]?.marketingAirline.code
        : "",
    has_skch: flight?.bookedItinerary.scheduleChange?.id || "false",
    skch_severity: flight?.bookedItinerary.scheduleChange?.severity,
  };
};

export const getViewedFlightExchangeModalProperties = (
  state: IStoreState,
  flight: BookedFlightItineraryWithDepartureTime
) => {
  const airlineMap = getAirlinesMap(state);
  const redirectUrl = flight ? getAirlineWebLink(flight, airlineMap) : "";
  const {
    bookedItinerary: { scheduleChange, travelItinerary },
    departureTime,
  } = flight;
  const { unscopedValue = "" } = travelItinerary.locators?.agent ?? {};
  const provider = unscopedValue.startsWith(TF_PREFIX)
    ? FlightProvider.travelFusion
    : FlightProvider.sabre;

  return {
    agent_locator: unscopedValue,
    agent_locator_provider: provider,
    booking_date: departureTime,
    days_until_departure: `${dayjs().diff(dayjs(departureTime), "days")} days`,
    contact_provider_air: redirectUrl,
    contact_reason: "change_flight",
    has_skch: scheduleChange?.id || "false",
    skch_severity: scheduleChange?.severity,
  };
};

export const getViewedMajorScheduleChangeProperties = createSelector(
  getSelectedFlight,
  (flight): MyTripsProperties => ({
    agent_locator:
      flight?.bookedItinerary.travelItinerary.locators?.agent.unscopedValue ||
      "",
    agent_locator_provider:
      flight?.bookedItinerary.travelItinerary.locators?.agent.unscopedValue ||
      "",
    days_until_departure: flight
      ? `${dayjs().diff(dayjs(flight.departureTime), "days")} days`
      : "",
    booking_date: flight?.departureTime || "",
    has_skch: flight?.bookedItinerary.scheduleChange?.id || "false",
    skch_severity: flight?.bookedItinerary.scheduleChange?.severity,
  })
);

export const getUserActionScheduleChangeSuccessProperties = createSelector(
  getSelectedFlight,
  (flight): UserActionScheduleChangeSuccessProperties => ({
    action: "",
    user_action: "",
    severity: flight?.bookedItinerary.scheduleChange?.severity || "",
    system_locator_provider: "",
    agent_locator_provider: "",
    agent_locator:
      flight?.bookedItinerary.travelItinerary.locators?.agent.unscopedValue ||
      "",
    system_locator:
      flight?.bookedItinerary.travelItinerary.locators?.agent.unscopedValue ||
      "",
    days_until_departure: flight
      ? `${dayjs(flight?.departureTime).diff(dayjs(), "day")}`
      : "",
    connection_time_valid: "",
    is_continuous: "",
    has_reprotection: "",
    provider: "",
    pcc: "",
    booking_date: "",
    itinerary_id: "",
    validating_carrier: "",
  })
);

export const getFetchingFutureFlightSucceded = (state: IStoreState) => {
  return state.tripsList.fetchFlightsCallState.future === CallState.Success;
};

export const getFetchingFutureHotelsSucceded = (state: IStoreState) => {
  return state.tripsList.fetchHotelsCallState.future === CallState.Success;
};

export const getMostUpcomingFlight = (state: IStoreState) => {
  const flightItinerariesWithType = addTypeToItinerary(
    state.tripsList.itineraries.future,
    ItineraryEnum.Flight
  );
  const sortedFlights = flightItinerariesWithType.sort((a, b) =>
    sortItinerariesByDate(a, b)
  );
  return sortedFlights[0];
};

export const getPaymentMethods = (state: IStoreState) =>
  state.tripsList.paymentMethods;

export const getHotelAvailabilityTrackingProperties = (state: IStoreState) =>
  state.tripsList.hotelAvailabilityResponse?.trackingPropertiesV2;

export const getCrossSellLatency = (state: IStoreState) =>
  state.tripsList.crossSellLatency;

export const getHotelAvailabilityLodgings = (state: IStoreState) =>
  state.tripsList.hotelAvailabilityResponse?.lodgings;

export const getHotelAvailabilityNextPageToken = (state: IStoreState) =>
  state.tripsList.hotelAvailabilityResponse?.nextPageToken;

export const getHotelBestOfferOverall = (state: IStoreState) =>
  state.tripsList.hotelAvailabilityResponse?.bestOverallOffer;

export const getHotelAvailabilityCallState = (state: IStoreState) =>
  state.tripsList.hotelAvailabilityCallState;

export const getHotelAvailabilitySearchLocationResult = (state: IStoreState) =>
  state.tripsList.hotelSearchLocationResult;

export const getHotelAvailabilityFromDate = (state: IStoreState) =>
  state.tripsList.hotelSearchFromDate;

export const getHotelAvailabilityUntilDate = (state: IStoreState) =>
  state.tripsList.hotelSearchUntilDate;

export const getSearchedNightCount = createSelector(
  getHotelAvailabilityFromDate,
  getHotelAvailabilityUntilDate,
  (fromDate, untilDate) => {
    if (!fromDate || !untilDate) {
      return null;
    }

    return dayjs(untilDate).diff(fromDate, "days");
  }
);

export const getHotelAvailabilityAdultsCount = (state: IStoreState) =>
  state.tripsList.hotelSearchAdultsCount;

export const getHotelAvailabilityChildren = (state: IStoreState) =>
  state.tripsList.hotelSearchChildrenCount;

export const getSelectedLodgingIndex = (state: IStoreState) =>
  state.tripsList.hotelSelectedLodgingIndex;

export const getHotelShopParams = createSelector(
  getHotelAvailabilityFromDate,
  getHotelAvailabilityUntilDate,
  getHotelAvailabilityAdultsCount,
  getHotelAvailabilityChildren,
  getSelectedAccountIndex,
  (fromDate, untilDate, adultsCount, children, selectedAccountIndex) => ({
    fromDate,
    untilDate,
    adultsCount,
    children,
    selectedAccountIndex,
  })
);

const priceSortComparer = (l1: Lodging, l2: Lodging) => {
  if (!l1.price || !l2.price) {
    if (!l1.price && !l2.price) {
      return 0;
    } else if (!l1.price) {
      return -1;
    }

    return 1;
  }

  return l1.price.nightlyPrice.fiat.value - l2.price.nightlyPrice.fiat.value;
};

export const orderByPriceLowToHigh = (lodgings: Lodging[]) => {
  return lodgings.sort(priceSortComparer);
};

export const getSortedLodgingsByPricing = createSelector(
  getHotelAvailabilityLodgings,
  (lodgings): Lodging[] => {
    if (lodgings) {
      const { availableLodgings, unavailableLodgings } = lodgings.reduce(
        (prev, curr) => {
          const available =
            typeof curr.available === "undefined" ? true : curr.available;

          if (available) prev.availableLodgings.push(curr);
          else prev.unavailableLodgings.push(curr);

          return prev;
        },
        {
          availableLodgings: [] as Lodging[],
          unavailableLodgings: [] as Lodging[],
        }
      );
      const sortedAvailableHotels = orderByPriceLowToHigh(availableLodgings);
      return sortedAvailableHotels.concat(unavailableLodgings);
    }
    return [];
  }
);

export const getHotelEntryProperties = createSelector(
  getAgentEmail,
  getHotelAvailabilitySearchLocationResult,
  getHotelAvailabilityFromDate,
  getIsFirstLaunch,
  getRewardsAccounts,
  getHotelAvailabilityAdultsCount,
  getHotelAvailabilityChildren,
  getMyTripsTravelWalletCredit,
  getMyTripsTravelWalletCreditBreakdown,
  getHotelAvailabilityLodgings,
  (
    agentEmail,
    location,
    fromDate,
    isFirstLaunch,
    rewardsAccounts,
    numAdults,
    children,
    credit,
    creditBreakdown,
    lodgings
  ): ITrackingProperties<HotelEntryProperties> => {
    return {
      properties: {
        delegated_to: agentEmail || "",
        first_launch: isFirstLaunch,
        market: location?.label || "",
        country: location?.subLabel || "",
        number_of_properties: lodgings?.length ?? 0,
        number_of_guests: numAdults + children.length,
        hotel_advance: fromDate
          ? `${dayjs(fromDate).diff(dayjs(), "day")} days`
          : "",
        rooms_searched: 1,
        rewards_accounts: rewardsAccounts
          .map((r) => r.productDisplayName)
          .join(","),
        has_credits: !!credit?.amount?.amount,
        credit_balance: !!credit?.amount?.amount
          ? Math.abs(credit.amount.amount)
          : 0,
        vx_statement_credit_balance:
          creditBreakdown
            ?.filter((b) => b.CreditDetail === "Statement")
            .reduce(
              (prev, curr) =>
                prev + (curr as StatementCreditDetail).usableAmount.amount,
              0
            ) || 0,
        ...location?.trackingPropertiesV2?.properties,
      },
      encryptedProperties: [
        location?.trackingPropertiesV2?.encryptedProperties ?? "",
      ],
    };
  }
);

export const getUnavailableLodgingCount = createSelector(
  getHotelAvailabilityLodgings,
  (lodgings) =>
    lodgings?.filter((lodging) =>
      typeof lodging.available === "undefined" ? false : !lodging.available
    ).length || 0
);

export const getAvailableLodgingCount = createSelector(
  getHotelAvailabilityLodgings,
  (lodgings) =>
    lodgings?.filter((lodging) =>
      typeof lodging.available === "undefined" ? false : lodging.available
    ).length || 0
);

export const getLuxuryCollectionLodgingCount = createSelector(
  getHotelAvailabilityLodgings,
  (lodgings) =>
    lodgings?.filter(
      (lodging) => lodging.lodgingCollection === LodgingCollectionEnum.Premier
    ).length
);

export const getLifestyleCollectionLodgingCount = createSelector(
  getHotelAvailabilityLodgings,
  (lodgings) =>
    lodgings?.filter(
      (lodging) => lodging.lodgingCollection === LodgingCollectionEnum.Lifestyle
    ).length
);

export const getViewedHotelListProperties = createSelector(
  getHotelEntryProperties,
  getSelectedAccount,
  getHotelAvailabilityTrackingProperties,
  getUnavailableLodgingCount,
  getAvailableLodgingCount,
  getHotelBestOfferOverall,
  getLuxuryCollectionLodgingCount,
  getLifestyleCollectionLodgingCount,
  (
    properties,
    account,
    trackingProperties,
    unavailableLodgingCount,
    availableLodgingCount,
    bestOverallOffer,
    luxuryLodgingCount,
    lifestyleLodgingCount
  ): ITrackingProperties<ViewedHotelListProperties> => {
    return {
      properties: {
        ...properties.properties,
        account_type_selected: account?.productDisplayName || "",
        unavailable_hotels: unavailableLodgingCount,
        available_hotels: availableLodgingCount,
        ...bestOverallOffer?.trackingPropertiesV2?.properties,
        has_offer: !!bestOverallOffer,
        account_use_type: account?.accountUseType,
        customer_account_role: account?.customerAccountRole,
        account_allow_rewards_redemption: account?.allowRewardsRedemption,
        pc_shown: luxuryLodgingCount || 0,
        lc_shown: lifestyleLodgingCount || 0,
        ...trackingProperties?.properties,
        rooms_searched: 1,
      },
      encryptedProperties: [
        bestOverallOffer?.trackingPropertiesV2?.encryptedProperties ?? "",
        trackingProperties?.encryptedProperties ?? "",
      ],
    };
  }
);

export const getFlightToHotelXSellTripCategory = (state: IStoreState) =>
  state.tripsList.flightToHotelCrossSellTripCategory;

const matchSearchQuery =
  (
    search: string,
    airports: Record<string, Airport | undefined>,
    airlines: Record<string, Airline | undefined>
  ) =>
  (
    itinerary:
      | ItineraryWithType
      | PendingGroundItinerary
      | PendingFlightItinerary
      | PendingHotelItinerary
      | BookedFlightItineraryWithDepartureTime
      | WatchAlertView
  ) => {
    if (!search.trim()) {
      return true;
    }

    // extract flight relevant data for search
    if (
      "bookedItinerary" in itinerary ||
      "pendingFlightItinerary" in itinerary
    ) {
      const isBooked = "bookedItinerary" in itinerary;
      const passengers = isBooked
        ? itinerary.bookedItinerary.passengers
        : itinerary.pendingFlightItinerary.passengers;

      const travelItinerary = isBooked
        ? itinerary.bookedItinerary.travelItinerary
        : itinerary.pendingFlightItinerary.travelItinerary;

      const id = isBooked
        ? itinerary.bookedItinerary.id
        : itinerary.pendingFlightItinerary.id;

      const isMultiTicket =
        travelItinerary.TravelItinerary ===
        TravelItineraryEnum.MultiTravelItinerary;

      const segments = isMultiTicket
        ? travelItinerary.travelItineraries
            .map((ti) => ti.slices.map((s) => s.segments))
            .flat(2)
        : travelItinerary.slices.map((s) => s.segments).flat();

      const confirmationCodes = isMultiTicket
        ? travelItinerary.travelItineraries
            .map((ti) => `H-${ti.locators?.agent.unscopedValue || ""}`)
            .flat(2)
            .join(" ")
        : `H-${travelItinerary.locators?.agent.unscopedValue || ""}`;

      const flightNumber = isMultiTicket
        ? travelItinerary.travelItineraries
            .map((ti) =>
              ti.slices.map((s) =>
                s.segments.map(
                  (seg) =>
                    `${seg.marketingAirline.code}${seg.marketingAirline.flightNumber}
                    ${seg.operatingAirline?.code}${seg.operatingAirline?.flightNumber}`
                )
              )
            )
            .flat(2)
            .join(" ")
        : travelItinerary.slices
            .map((s) =>
              s.segments.map(
                (seg) =>
                  `${seg.marketingAirline.code}${seg.marketingAirline.flightNumber}
                  ${seg.operatingAirline?.code}${seg.operatingAirline?.flightNumber}`
              )
            )
            .flat()
            .join(" ");

      const segmentsInfo = segments
        .map((s) => [
          s.cabinClassName,
          s.origin.locationCode,
          s.origin.terminalName,
          airports[s.origin.locationCode]?.name,
          airports[s.origin.locationCode]?.cityName,
          airports[s.origin.locationCode]?.servedCityName,
          s.destination.locationCode,
          airports[s.destination.locationCode]?.name,
          airports[s.destination.locationCode]?.cityName,
          airports[s.destination.locationCode]?.servedCityName,
          s.destination.terminalName,
          s.marketingAirline.flightNumber,
          s.marketingAirline.code,
          airlines[s.marketingAirline.code]?.displayName,
        ])
        .flat(2)
        .filter(Boolean)
        .join(" ");

      const alonePassengers = passengers.alone.map(
        (p) => `${p.person.givenName} ${p.person.surname}`
      );
      const withLapInfantsPassengers = passengers.withLapInfants.map((p) =>
        "adult" in p
          ? `${p.adult.person.givenName} ${p.adult.person.surname}`
          : `${p.person.givenName} ${p.person.surname}`
      );
      const allPassengers = [...alonePassengers, ...withLapInfantsPassengers];

      return search
        .trim()
        .split(" ")
        .every((searchPart) =>
          [
            "flight",
            id,
            ...allPassengers,
            segmentsInfo,
            confirmationCodes,
            flightNumber,
          ].some((s) => s.toLowerCase().includes(searchPart.toLowerCase()))
        );
    }

    // extract hotel relevant data for search
    if ("reservation" in itinerary || "pendingReservation" in itinerary) {
      // const isBooked = "reservation" in itinerary;

      let guests: Person[],
        reservationBookingId: string,
        lodgingData: LodgingData | HomesPriceQuoteListing;

      if ("reservation" in itinerary) {
        const { reservation } = itinerary;
        const isHotel = "reservationId" in reservation;
        guests = isHotel ? reservation.guests : [reservation.primaryGuest];

        reservationBookingId = isHotel
          ? `H-${reservation.reservationId} ${reservation.reservationBookingId}`
          : `H-${reservation.id.value}`;

        lodgingData = isHotel ? reservation.lodgingData : reservation.listing;
      } else {
        guests = itinerary.pendingReservation.guests;

        reservationBookingId = `H-${itinerary.pendingReservation.id}`;

        lodgingData = itinerary.pendingReservation.lodgingData;
      }

      const hotelName = lodgingData?.name ?? "";

      let hotelAddress: string = "";
      if (lodgingData) {
        if ("areaName" in lodgingData) {
          hotelAddress = `${lodgingData.address} ${lodgingData.state} ${lodgingData.countryCode}`;
        } else if ("line1" in lodgingData?.address) {
          hotelAddress = `${lodgingData?.address.line1} ${lodgingData?.address.line2} ${lodgingData?.city} ${lodgingData?.country}`;
        } else {
          hotelAddress = `${lodgingData?.address.city} ${lodgingData?.country} ${lodgingData?.address.stateOrProvince}`;
        }
      }

      const guestNames = guests
        ? guests.map((g) => `${g.givenName} ${g.surname}`).join(" ")
        : "";

      return search
        .trim()
        .split(" ")
        .every((searchPart) =>
          [
            "hotel",
            reservationBookingId,
            hotelName,
            hotelAddress,
            guestNames,
          ].some((s) => s.toLowerCase().includes(searchPart.toLowerCase()))
        );
    }

    // extract cars relevant data for search
    if ("bookResult" in itinerary || "driver" in itinerary) {
      const isBooked = "bookResult" in itinerary;
      const driver = isBooked ? itinerary.bookResult.driver : itinerary.driver;

      const vehicle = isBooked
        ? itinerary.bookResult.vehicle
        : itinerary.vehicle;

      const pickUp = isBooked
        ? `${itinerary.bookResult.pickUp.location.name} ${itinerary.bookResult.pickUp.location.address}`
        : itinerary.pickUp.address;

      const dropOff = isBooked
        ? `${itinerary.bookResult.dropOff.location.name} ${itinerary.bookResult.dropOff.location.address}`
        : itinerary.dropOff.address;

      const carName = `${vehicle.name}`;
      const driverName = `${driver.givenName} ${driver.surname}`;

      const bookingId = isBooked
        ? `H-${itinerary.bookResult.groundBookingId} H-${itinerary.bookResult.bookingId} H-${itinerary.bookResult.tripSummary?.userBookingId}`
        : `H-${itinerary.id}`;

      return search
        .trim()
        .split(" ")
        .every((searchPart) =>
          ["car", carName, driverName, pickUp, dropOff, bookingId].some((s) =>
            s.toLowerCase().includes(searchPart.toLowerCase())
          )
        );
    }

    // extract watch relevant data for search
    if ("key" in itinerary) {
      const { value } = itinerary.key;
      const { departureDate, returnDate, origin, destination } = value;
      const departureDateFormatted = dayjs(departureDate).format("MMM DD");
      const returnDateFormatted = dayjs(returnDate).format("MMM DD");
      const originName = airports[origin.code]?.name;
      const originCityName = airports[origin.code]?.cityName;
      const originServedCityName = airports[origin.code]?.servedCityName;
      const destinationName = airports[destination.code]?.name;
      const destinationCityName = airports[destination.code]?.cityName;
      const destinationServedCityName =
        airports[destination.code]?.servedCityName;
      const recommendation =
        itinerary.recommendation === "Buy" ? "buy book now" : "wait";

      return search
        .trim()
        .split(" ")
        .every((searchPart) =>
          [
            "watch",
            recommendation,
            departureDateFormatted,
            returnDateFormatted,
            origin.code,
            originName,
            originCityName,
            originServedCityName,
            destination.code,
            destinationName,
            destinationCityName,
            destinationServedCityName,
          ].some((s) => s?.toLowerCase().includes(searchPart.toLowerCase()))
        );
    }

    return true;
  };

export const getCrossSellOffers = (state: IStoreState) =>
  state.tripsList.crossSellOffers;

export const getFirstCrossellOffer = createSelector(
  getCrossSellOffers,
  (offers) => offers[0]
);

export const getFetchHotelCrossSellCallState = (state: IStoreState) =>
  state.tripsList.fetchHotelCrossSellCallState;

export const getAvailableCrossSellHotels = createSelector(
  getHotelAvailabilityLodgings,
  (lodgings) => lodgings?.filter((lodging) => !!lodging.available)
);

export const isPackagesEnabledSelector = (state: IStoreState) =>
  state.tripsList.experiments?.[ActiveExperiments.PACKAGES_EXPERIMENT] !==
  CONTROL;
