import React, { useEffect, useState } from "react";
import {
  createGenerateClassName,
  StylesProvider,
  ThemeProvider,
} from "@material-ui/core";
import { AgentBanner, useDeviceTypes } from "halifax";
import { IntlProvider } from "react-intl";
import { Provider, useDispatch } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import {
  TripsModuleProps,
  installColorConfig,
  TripsClientAssetProps,
  SessionInfo,
  CorpSessionInfo,
} from "redmond";
import { useMedalliaFeedback } from "b2b-base/src/components/MedalliaFeedback";

import ExperimentsProvider, {
  ActiveExperiments,
  useExperiments,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  HOTEL_CFAR_REFUND_DISPLAY_VARIANTS,
  PACKAGES_EXPERIMENT_VARIANTS,
} from "./context/experiments";
import useWindowEventListener from "./hooks/useWindowEventListener";
import { eventsToListen } from "./utils/events";
import {
  PATH_HOME,
  PATH_DISRUPTION_PROTECTION_OVERVIEW,
  PATH_HOTEL_FREEZE_OVERVIEW,
} from "./utils/paths";
import * as frenchTranslations from "./lang/fr.json";
import * as englishTranslations from "./lang/en.json";
import { store } from "./store";
import { TripsList, DisruptionProtection } from "./pages";
import AxiosInterceptors from "./components/AxiosInterceptors";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { RewardsBanner } from "./modules/rewards/components";
import fetchUserInfo from "./api/v1/user/fetchUserInfo";
import UserSourceProvider from "./context/userSource";
import { setDisruptionProtectionExperiments } from "./pages/DisruptionProtection/actions/actions";
import { setPriceFreezeExperiments } from "./pages/PriceFreeze/actions/actions";
import "./App.scss";
import { PriceFreeze } from "./pages/PriceFreeze";
import { setTripsListExperiments } from "./pages/TripsList/actions/actions";
import { TravelerModalWrapper } from "./pages/common";
import { isCorpTenant } from "@capone/common";
import { config } from "./api/config";

function loadLocaleData(locale: string): any {
  switch (locale) {
    case "fr":
      return frenchTranslations;
    default:
      return englishTranslations;
  }
}
const generateClassName = createGenerateClassName({
  productionPrefix: "ptTripsModule",
  seed: "ptTripsModule",
});

export const ClientContext = React.createContext<
  Partial<TripsClientAssetProps>
>({});

const App = (props: TripsModuleProps) => {
  const {
    baseHistory,
    clientAssets,
    colors,
    experiments,
    isAgentPortal,
    language,
    theme,
  } = props;
  const { matchesMobile } = useDeviceTypes();
  const [activeTheme, setActiveTheme] = useState(theme);
  const [locale, setLocale] = useState(language);
  const [messages, setMessages] = useState(loadLocaleData(locale).default);
  const [sessionInfo, setSessionInfo] = useState(clientAssets.sessionInfo);

  useEffect(() => {
    // note: it connects Cypress with the Redux store for testing
    if ((window as any).Cypress) {
      (window as any).store = store;
    }

    const fetchUser = async () => {
      try {
        const userInfoResponse = await fetchUserInfo();
        setSessionInfo(userInfoResponse);
      } catch (error) {
        console.error(error);
      }
    };
    fetchUser();
  }, []);

  installColorConfig(colors);

  const handleThemeChanged = (e: CustomEvent) => {
    setActiveTheme(e.detail);
    console.log("THEME RECEIVED:", e.detail.palette.type);
  };
  const handleLocaleChanged = (e: CustomEvent) => {
    setLocale(e.detail);
    const messages = loadLocaleData(e.detail);
    setMessages(messages.default);
  };
  useWindowEventListener(eventsToListen.HOST_THEME_CHANGED, handleThemeChanged);
  useWindowEventListener(
    eventsToListen.HOST_LOCALE_CHANGED,
    handleLocaleChanged
  );

  const { firstName, lastName } = sessionInfo?.userInfo || {
    firstName: "",
    lastName: "",
  };

  return (
    <Provider store={store}>
      <ExperimentsProvider initState={experiments}>
        <UserSourceProvider>
          <Router history={baseHistory}>
            <AxiosInterceptors isAgentPortal={isAgentPortal} />
            <ClientContext.Provider
              value={{ ...clientAssets, isAgentPortal, sessionInfo }}
            >
              <div className="App">
                <StylesProvider generateClassName={generateClassName}>
                  <ThemeProvider theme={activeTheme}>
                    {messages != null ? (
                      <IntlProvider
                        locale={locale}
                        defaultLocale="en"
                        messages={messages}
                      >
                        <Switch>
                          {isAgentPortal ? (
                            <Route path="*">
                              <AgentBanner
                                firstName={firstName}
                                lastName={lastName}
                              />
                            </Route>
                          ) : (
                            <Route path="*">
                              <RewardsBanner />
                            </Route>
                          )}
                        </Switch>
                        <Body
                          isMobile={matchesMobile}
                          sessionInfo={sessionInfo}
                        />
                      </IntlProvider>
                    ) : (
                      <div>Loading</div>
                    )}
                  </ThemeProvider>
                </StylesProvider>
              </div>
            </ClientContext.Provider>
          </Router>
        </UserSourceProvider>
      </ExperimentsProvider>
    </Provider>
  );
};

const Body = (props: {
  isMobile: boolean;
  sessionInfo?: SessionInfo | CorpSessionInfo;
}) => {
  const { isMobile, sessionInfo } = props;
  const dispatch = useDispatch();
  const expState = useExperiments();
  const userRoles = isCorpTenant(config.TENANT)
    ? (sessionInfo as CorpSessionInfo).corporateInfo.role
    : [];

  // note: this useEffect helps to store some experiments in redux, so that some selectors can be created for unifying the gating logic
  useEffect(() => {
    dispatch(
      setDisruptionProtectionExperiments({
        [ActiveExperiments.DisruptionProtection24hRule]: getExperimentVariant(
          expState.experiments,
          ActiveExperiments.DisruptionProtection24hRule
        ),
        [ActiveExperiments.RAPID_REBOOK_RENAME]: getExperimentVariant(
          expState.experiments,
          ActiveExperiments.RAPID_REBOOK_RENAME
        ),
        [ActiveExperiments.FINTECH_CSAT]: getExperimentVariant(
          expState.experiments,
          ActiveExperiments.FINTECH_CSAT
        ),
      })
    );
    dispatch(
      setPriceFreezeExperiments({
        [ActiveExperiments.HOTELS_PRICE_FREEZE]: getExperimentVariant(
          expState.experiments,
          ActiveExperiments.HOTELS_PRICE_FREEZE
        ),
      })
    );

    dispatch(
      setTripsListExperiments({
        [ActiveExperiments.HOTEL_CFAR_REFUND_DISPLAY]:
          getExperimentVariantCustomVariants(
            expState.experiments,
            ActiveExperiments.HOTEL_CFAR_REFUND_DISPLAY,
            HOTEL_CFAR_REFUND_DISPLAY_VARIANTS
          ),
        [ActiveExperiments.FINTECH_CSAT]: getExperimentVariant(
          expState.experiments,
          ActiveExperiments.FINTECH_CSAT
        ),
        [ActiveExperiments.PACKAGES_EXPERIMENT]:
          getExperimentVariantCustomVariants(
            expState.experiments,
            ActiveExperiments.PACKAGES_EXPERIMENT,
            PACKAGES_EXPERIMENT_VARIANTS
          ),
      })
    );
  }, [expState]);

  useMedalliaFeedback();

  return (
    <ErrorBoundary isMobile={isMobile}>
      <Route path={PATH_HOME} exact>
        <TripsList />
      </Route>
      <Route path={PATH_DISRUPTION_PROTECTION_OVERVIEW}>
        <DisruptionProtection />
      </Route>
      <Route path={PATH_HOTEL_FREEZE_OVERVIEW}>
        <PriceFreeze />
      </Route>
      {isCorpTenant(config.TENANT) && (
        <TravelerModalWrapper isMobile={isMobile} userRoles={userRoles} />
      )}
    </ErrorBoundary>
  );
};

export default App;
