import dayjs from "dayjs";

import { CurrencyFormatters } from "halifax";
import { last, uniq } from "lodash";
import React from "react";
import {
  AirlineMap,
  Airport,
  AirportMap,
  Amount,
  BookedFlightItineraryWithDepartureTime,
  FlightItinerarySegment,
  FlightItinerarySlice,
  getDepartureSlice,
  getReturnSlice,
  getSlicesFromTravelItinerary,
  TravelItineraryEnum,
  getUniqueCitiesFromTripSlices,
  getTruncatedCityList,
} from "redmond";
export const BOOK_WITH_CREDIT = "Book With Credit";
export const TC_WORTH = (credit?: Amount) =>
  `Your travel credit is worth <b>${CurrencyFormatters.get(
    credit?.currency
  ).format(credit?.amount ?? 0)}</b>.`;
export const TITLE = "My Trips";
export const SUBTITLE = "View and manage upcoming and past trips";
export const FAQ_TITLE = "Questions about your trip?";
export const FAQ_CTA = "View FAQs";
export const SUPPORT_CTA = "Contact Support";
export const DEPARTURE = "Departure";
export const RETURN = "Return";
export const FLIGHT = "Flight";
export const MULTICITY_FLIGHT = "Multi-city flight";
export const CONFIRMATION = "Confirmation:";
export const VIEW_DETAILS = "View details";
export const VIEW_LESS = "View Less";
export const DEPARTS_ON = "Departs on";
export const DEPARTED_ON = "Departs";
export const ROUND_TRIP = "Round-trip";
export const ONE_WAY = "One way";
export const TWENTY_FOUR_HOURS = "24 hours";
export const OUTBOUND = "Outbound";
export const MAJOR_SCHEDULE_CHANGE_LABEL = (
  airline: string,
  time: string = TWENTY_FOUR_HOURS
) => `A major schedule change has been made by ${airline}. You have ${time} to`;
export const MODIFIED_TOOLTIP = (
  airline: string,
  time: string = TWENTY_FOUR_HOURS
) =>
  `This flight has been modified by ${airline} due to a major schedule change. Please review your revised flight details, as this will be automatically accepted within ${time} unless you choose to decline.`;
export const CANCELED_TOOLTIP = (
  airline: string,
  time: string = TWENTY_FOUR_HOURS
) =>
  `This flight has been canceled by ${airline}. Please review your revised flight details, as this will be automatically accepted within ${time} unless you choose to decline.`;
export const MAJOR_SCHEDULE_CHANGE_BANNER_CTA_LABEL =
  "accept or decline this change.";
export const MAJOR_SCHEDULE_CHANGE_CTA_LABEL = "View schedule change";
export const MINOR_SCHEDULE_CHANGE_LABEL =
  "A modification has been made to your itinerary.";
export const GET_CANCELLED_SEGMENT_BANNER_LABEL = (changes: number) =>
  changes >= 1
    ? "A part of this itinerary has been cancelled."
    : "This itinerary has been cancelled.";
export const PENDING_TOOLTIP =
  "Your booking is still being processed. You’ll receive an email when your booking is confirmed.";
("Protected by <b>missed connection guarantee</b>. <span class='underline'>Learn more</span>");
/** @see https://hopper-jira.atlassian.net/browse/COTA-1093 */
export const PROTECTED_BY_MCR_VI =
  "Protected by <b>missed connection rebooking</b>. <span class='underline'>Learn more</span>";
export const PROTECTED_BY_MCR_VI_REBOOKED =
  "You successfully rebooked a portion of this flight. The remaining itinerary is still active.";
export const BOOKED_WITH_MISSED_CONNECTION_GUARANTEE =
  "Booked with missed connection guarantee.";
/** @see https://hopper-jira.atlassian.net/browse/COTA-1093 */
export const BOOKED_WITH_MISSED_CONNECTION_REBOOKING =
  "Booked with missed connection rebooking.";
export const PENDING = "Pending";
export const CANCELLED = "Canceled";
export const MODIFIED = "Itinerary Modified";
export const CANCELLED_ITINERARY = "This itinerary has been canceled";
export const PENDING_CANCELLATION =
  "This cancellation is in progress, a confirmation email will be sent within 1-2 hours";
export const DATE_FORMAT = "ddd, MMM D";
export const TIME_FORMAT = "h:mm a";
export const FARE_DETAILS = "Fare Details";
export const SEAT_SELECTION = "Seat Selection";
export const SEAT_UPDATE_INSTR = {
  PREFIX:
    "As we cannot add or modify seat assignments once a booking has been made, please visit",
  SEPARATOR: "and",
  SUFFIX: "if you would like to do so.",
};
export const SEAT_UPDATE_INSTRUCTIONS = (
  slice: FlightItinerarySlice,
  airlineMap: AirlineMap
) => {
  const airlines = getAirlineLinks(slice, airlineMap);
  const fmtAirlines = airlines.join(" and ");

  return `As we cannot add or modify seat assignments once a booking has been made, please visit ${fmtAirlines} if you would like to do so.`;
};
export const HOW_TC_WORKS = "How does an airline travel credit work?";
export const REFER_EMAIL = "Refer Airline Email";
export const REFER_EMAIL_TOOLTIP =
  "The airline will email you directly to confirm this booking, generally within a few minutes but please allow up to 72 hours. If not received, you can also confirm your booking status on the airline’s website using the confirmation code we provided to you by email.";

export const getHeaderText = (
  departureTime?: string,
  returnTime?: string,
  isMultiCity?: boolean
) => {
  const title = isMultiCity ? MULTICITY_FLIGHT : FLIGHT;

  const departureTimeWithoutTZ = departureTime?.slice(0, -6);
  const returnTimeWithoutTZ = returnTime?.slice(0, -6);

  return departureTimeWithoutTZ ? (
    <>
      <strong>{title}</strong> <span className="separator">|</span>{" "}
      {dayjs.utc(departureTimeWithoutTZ).format(DATE_FORMAT)}
      {returnTime ? ` - ${dayjs(returnTimeWithoutTZ).format(DATE_FORMAT)}` : ""}
    </>
  ) : (
    <strong>{title}</strong>
  );
};

export const getFlightHeader = (
  flight: BookedFlightItineraryWithDepartureTime
) => {
  const { travelItinerary } = flight.bookedItinerary;
  let outboundSegment: FlightItinerarySegment;
  let returnSegment: FlightItinerarySegment;

  switch (travelItinerary.TravelItinerary) {
    case TravelItineraryEnum.SingleTravelItinerary: {
      const firstSlice = travelItinerary.slices[0];
      outboundSegment = firstSlice.segments[0];
      returnSegment = last(firstSlice.segments) ?? outboundSegment;
      break;
    }
    case TravelItineraryEnum.MultiTravelItinerary: {
      const firstSlice = travelItinerary.travelItineraries[0].slices[0];
      outboundSegment = firstSlice.segments[0];
      returnSegment = last(firstSlice.segments) ?? outboundSegment;
      break;
    }
    default:
      return "";
  }

  return `${outboundSegment.origin.locationCode} to ${returnSegment.destination.locationCode}`;
};

export const getMulticityFlightHeader = (
  flight: BookedFlightItineraryWithDepartureTime,
  airports: AirportMap
) => {
  const { travelItinerary } = flight.bookedItinerary;
  const tripSlices = getSlicesFromTravelItinerary(travelItinerary);
  const uniqueCityNames = getUniqueCitiesFromTripSlices(tripSlices, airports);

  return getTruncatedCityList(uniqueCityNames);
};

export const getItineraryDetailsHeader = (
  flight: BookedFlightItineraryWithDepartureTime,
  isOutgoing: boolean,
  airportMap: { [key: string]: Airport },
  multicitySliceIndex?: number
) => {
  const isMultiCity = multicitySliceIndex !== undefined;

  let tripSlice;
  if (isMultiCity) {
    const slices = getSlicesFromTravelItinerary(
      flight.bookedItinerary.travelItinerary
    );
    tripSlice = slices[multicitySliceIndex];
  } else {
    tripSlice = isOutgoing
      ? getDepartureSlice(flight.bookedItinerary)
      : getReturnSlice(flight.bookedItinerary);
  }

  const lastIndexOfSegments = tripSlice!.segments.length - 1;
  return ` to ${
    airportMap[
      tripSlice!.segments[lastIndexOfSegments].destination.locationCode
    ]?.cityName
  } ${isMultiCity ? "-" : ""}(${
    tripSlice!.segments[lastIndexOfSegments].destination.locationCode
  }) on ${dayjs(tripSlice!.segments[0]?.scheduledDeparture).format(
    DATE_FORMAT
  )}`;
};

export const getMinorScheduleChangeTooltip = (
  sliceIndex: number,
  airlineName: string,
  segment?: FlightItinerarySegment,
  airportMap?: { [key: string]: Airport }
) => {
  if (segment) {
    const sliceType = sliceIndex > 0 ? "return" : "outbound";
    const {
      destination: { locationCode: destCode },
      origin: { locationCode: originCode },
      scheduledArrival,
      scheduledDeparture,
    } = segment;
    const origin = airportMap?.[originCode]
      ? airportMap[originCode]?.cityName
      : originCode;
    const destination = airportMap?.[destCode]
      ? airportMap[destCode]?.cityName
      : destCode;

    return `A modification has been made to your itinerary. Your ${sliceType} from <strong>${origin}</strong> to <strong>${destination}</strong> has changed. This flight now departs at <strong>${dayjs(
      scheduledDeparture
    ).format(TIME_FORMAT)}</strong> and returns at <strong>${dayjs(
      scheduledArrival
    ).format(TIME_FORMAT)}</strong>`;
  }

  return `This flight has been modified by ${airlineName} due to a minor schedule change. Please review your revised flight details, no further action is required.`;
};

export const GET_HELP_DISRUPTED_FLIGHT_OLD = "Rapidly rebook";
export const GET_HELP_DISRUPTED_FLIGHT_NEW = "Flight disruption assistance";
export const CHANGE_FLIGHT = "Change Flight";
export const REBOOK_CONNECTING_FLIGHT = "Rebook Connecting Flight";
export const CANCEL_FLIGHT = "Cancel Flight";
export const RESEND_CONFIRMATION = "Resend Confirmation";
export const CANCEL_FOR_ANY_REASON = "Cancel for Any Reason";

export const DISRUPTION_BANNER_AIRLINE_CANCELLATION_LINK_PREFIX_OLD =
  "Your flight has been canceled. Since you added rapid rebooking to your flight, you can ";
export const DISRUPTION_BANNER_AIRLINE_CANCELLATION_LINK_PREFIX_NEW =
  "Your flight has been canceled. Since you added flight disruption assistance to your flight, you can ";
export const DISRUPTION_BANNER_AIRLINE_CANCELLATION_LINK_LABEL =
  "rebook or get a refund";
export const DISRUPTION_BANNER_AIRLINE_CANCELLATION_LINK_SUFFIX = ".";

export const DISRUPTION_BANNER_DELAY_LINK_PREFIX_OLD =
  "Your flight is delayed. Since you added rapid rebooking to your flight, you can ";
export const DISRUPTION_BANNER_DELAY_LINK_PREFIX_NEW =
  "Your flight is delayed. Since you added flight disruption assistance to your flight, you can ";
export const DISRUPTION_BANNER_DELAY_LINK_LABEL = "rebook or get a refund";
export const DISRUPTION_BANNER_DELAY_LINK_SUFFIX = ".";

export const DISRUPTION_BANNER_MISSED_CONNECTION_LINK_PREFIX_OLD =
  "We've detected a delay that may cause you to miss a connecting flight. Since you added rapid rebooking to your flight, you can ";
export const DISRUPTION_BANNER_MISSED_CONNECTION_LINK_PREFIX_NEW =
  "We've detected a delay that may cause you to miss a connecting flight. Since you added flight disruption assistance to your flight, you can ";
export const DISRUPTION_BANNER_MISSED_CONNECTION_LINK_LABEL =
  "rebook or get a refund";
export const DISRUPTION_BANNER_MISSED_CONNECTION_LINK_SUFFIX = ".";

export const DISRUPTION_BANNER_DEFAULT_LINK_PREFIX =
  "We've detected a flight disruption. You can rebook or request a refund through your ";
export const DISRUPTION_BANNER_DEFAULT_LINK_LABEL_OLD = "rapid rebooking";
export const DISRUPTION_BANNER_DEFAULT_LINK_LABEL_NEW =
  "flight disruption assistance";
export const DISRUPTION_BANNER_DEFAULT_LINK_SUFFIX = ".";

export const DISRUPTION_EXERCISED_BANNER_REBOOK_LUGGAGE_AND_RETURN_FLIGHT_ONE =
  "You have successfully rebooked your disrupted flight. Remember to confirm your new flight details, re-check any";
export const DISRUPTION_EXERCISED_BANNER_REBOOK_LUGGAGE_AND_RETURN_FLIGHT_TWO =
  "and confirm the status of your";
export const DISRUPTION_EXERCISED_BANNER_REBOOK_LUGGAGE_ONLY_DETAILS_ONE =
  "You have successfully rebooked your disrupted flight. Remember to confirm your new flight details and re-check any";
export const DISRUPTION_EXERCISED_BANNER_REBOOK_MOBILE_OVERVIEW =
  "You have successfully rebooked your disrupted flight. Learn more about next steps from the flight details screen.";
export const DISRUPTION_EXERCISED_BANNER_REFUND =
  "Your disrupted flight has been refunded.";

export const LUGGAGE = "luggage";
export const RETURN_FLIGHT = "return flight";
export const PERIOD = ".";

export const DISRUPTION_EXERCISED_BANNER_REBOOK_LUGGAGE_AND_RETURN_FLIGHT_FULL_TEXT = `${DISRUPTION_EXERCISED_BANNER_REBOOK_LUGGAGE_AND_RETURN_FLIGHT_ONE} ${LUGGAGE} ${DISRUPTION_EXERCISED_BANNER_REBOOK_LUGGAGE_AND_RETURN_FLIGHT_TWO} ${RETURN_FLIGHT}${PERIOD}`;
export const DISRUPTION_EXERCISED_BANNER_REBOOK_LUGGAGE_ONLY_DETAILS_FULL_TEXT = `${DISRUPTION_EXERCISED_BANNER_REBOOK_LUGGAGE_ONLY_DETAILS_ONE} ${LUGGAGE}${PERIOD}`;

export const TRAVEL_OFFER_APPLIED = "Travel offer applied";
export const TRAVEL_CREDIT_APPLIED = "Travel credit applied";

export const LEARN_MORE_ABOUT_LUGGAGE =
  "<b>Learn more</b> about re-checking your luggage";
export const LEARN_MORE_ABOUT_RETURN_FLIGHT =
  "<b>Learn more</b> about confirming the status of your return flight";

export const CHECK_IN_CTA_TEXT = "Check-in for your flight";

const getAirlineLinks = (
  slice: FlightItinerarySlice,
  airlineMap: AirlineMap
): string[] => {
  const airlineLinks: string[] = [];

  slice.segments.forEach((seg) => {
    const airline = airlineMap[seg.marketingAirline?.code ?? ""];

    if (airline) {
      const {
        displayName,
        webLinks: { homePage, manageBooking, seatSelection },
      } = airline;
      const linkText = `${displayName}${
        displayName.endsWith("s") ? "'" : "'s"
      } website`;
      const link = seatSelection || manageBooking || homePage;

      if (link) {
        airlineLinks.push(
          `<a class="action-link" href="${link}" target="_blank">${linkText}</a>`
        );
      } else {
        airlineLinks.push(linkText);
      }
    }
  });

  return uniq(airlineLinks);
};

export const POST_BOOKING_ADD_ONS = "Post-purchase add-ons";
export const POST_BOOKING_ADD_ON_DATE = (date: string) => {
  const dateFormatted = dayjs(date).format("ddd, MMMM D");
  return `Added on ${dateFormatted}`;
};
export const TOTAL_POST_BOOKING_ADD_ONS = "Post-purchase add-ons total";
