import {
  Airline,
  Airport,
  BookedFlightItineraryWithDepartureTime,
  CarReservation,
  CarItineraryResponseSuccess,
  CallState,
  MyTripsFilter,
  WatchAlertView,
  HotelItinerary,
  IOpenModal,
  PriceFreezeView,
  CancellationFareRulesSuccess,
  CancellationResponse,
  FlightItineraryState,
  HotelItineraryState,
  CarReservationState,
  CarItineraryResponseEnum,
  IRequestPassengerEdit,
  AvailabilityResponse,
  IResult,
  GuestsSelection,
  TripCategory,
  HotelCfarCancelScenario,
  HotelCfarCancelScenarioEnum,
  HotelPriceFreezeSummary,
  Payment,
  HomesItinerary,
  TripRequests,
  ActiveCrossSellOffer,
  PackageItinerary,
} from "redmond";
import { TripSummaryContext } from "redmond";
import { actions, actionTypes } from "../actions";
import {
  ActiveExperiments,
  HotelCfarRefundDisplayVariantType,
  DefaultVariantType,
  PackagesExperimentVariantType,
} from "../../../context/experiments";

export enum HotelAvailabilityCallState {
  NotCalled = "NotCalled",
  InitialSearchCallInProcess = "InitialSearchCallInProcess",
  FollowUpSearchCallInProcess = "FollowUpSearchCallInProcess",
  InitialSearchCallSuccess = "InitialSearchCallSuccess",
  FollowUpSearchCallSuccess = "FollowUpSearchCallSuccess",
  // when a FollowUpSearch returns empty lodgings
  Complete = "Complete",
  Failed = "Failed",
}
export interface ITripsListState
  extends IWatchState,
    ITripsListExperimentState {
  tripsFilter: MyTripsFilter;
  itineraries: { [key: string]: BookedFlightItineraryWithDepartureTime[] };
  homeItineraries: { [key: string]: HomesItinerary[] };
  hotelItineraries: { [key: string]: HotelItinerary[] };
  carItineraries: { [key: string]: CarReservation[] };
  packageItineraries: { [key: string]: PackageItinerary[] };
  airports: { [key: string]: Airport };
  priceFreezeAirports: { [key: string]: Airport };
  airlines: { [key: string]: Airline };
  priceFreezeAirlines: { [key: string]: Airline };
  selectedFlight: BookedFlightItineraryWithDepartureTime | null;
  selectedHotel: HotelItinerary | null;
  selectedHome: HomesItinerary | null;
  selectedCar: CarReservation | null;
  selectedPackage: PackageItinerary | null;
  selectedPackageHotel: HotelItinerary | null;
  selectedPackageFlight: BookedFlightItineraryWithDepartureTime | null;
  fetchFlightsCallState: { [key: string]: CallState };
  fetchHotelsCallState: { [key: string]: CallState };
  fetchHomesCallState: { [key: string]: CallState };
  fetchCarsCallState: { [key: string]: CallState };
  fetchPackagesCallState: { [key: string]: CallState };
  acceptScheduleChangeCallState: CallState;
  denyScheduleChangeCallState: CallState;
  openModal: IOpenModal;
  requestPassengerEdit: IRequestPassengerEdit | null;
  listPriceFreezeCallState: CallState;
  priceFreezes: Array<PriceFreezeView>;
  listHotelPriceFreezeCallState: CallState;
  hotelPriceFreezes: Array<HotelPriceFreezeSummary>;
  selectedCfar: CancellationFareRulesSuccess | null;
  selectedCfarItineraryId: string | null;
  fetchFlightCfarInfoCallState: CallState;
  flightCfarCancellationResponse: CancellationResponse | null;
  confirmFlightCfarCancellationCallState: CallState;
  hotelAvailabilityResponse?: AvailabilityResponse;
  hotelSearchFromDate: Date | null;
  hotelSearchUntilDate: Date | null;
  hotelSearchLocationResult: IResult | null;
  hotelSearchAdultsCount: number;
  hotelSearchChildrenCount: number[];
  hotelSelectedLodgingIndex: number | null;
  hotelAvailabilityCallState: HotelAvailabilityCallState;
  flightToHotelCrossSellTripCategory: TripCategory;
  hotelCfarCancellationPolicyScenario: HotelCfarCancelScenario | null;
  hotelCfarCancellationPolicyCallState: CallState;
  selectedHotelCfarReservationId: string | null;
  confirmHotelCfarCancellationCallState: CallState;
  crossSellLatency: number | null;
  paymentMethods: Payment[];
  listPaymentMethodCallState: CallState;
  tripRequests: TripRequests;
  searchQuery: string;
  crossSellOffers: ActiveCrossSellOffer[];
  fetchHotelCrossSellCallState: CallState;
}

export interface ITripsListExperimentState {
  experiments: ITripsListExperiments | null;
}

export interface ITripsListExperiments {
  [ActiveExperiments.HOTEL_CFAR_REFUND_DISPLAY]: HotelCfarRefundDisplayVariantType;
  [ActiveExperiments.FINTECH_CSAT]: DefaultVariantType;
  [ActiveExperiments.PACKAGES_EXPERIMENT]: PackagesExperimentVariantType;
}

export const getExperimentState = (
  state: ITripsListState | undefined
): ITripsListExperimentState => {
  return {
    experiments: state?.experiments ?? initialState.experiments,
  };
};

export interface IWatchState {
  watches: WatchAlertView[];
  watchContext: TripSummaryContext | null;
  updateWatchCallState: CallState;
  listWatchCallState: CallState;
  deleteWatchCallState: CallState;
}

const initialState: ITripsListState = {
  experiments: null,
  tripsFilter: MyTripsFilter.UPCOMING_TRIPS,
  watchContext: null,
  selectedFlight: null,
  selectedHotel: null,
  selectedHome: null,
  selectedCar: null,
  selectedPackage: null,
  selectedPackageFlight: null,
  selectedPackageHotel: null,
  itineraries: {
    canceled: [],
    past: [],
    present: [],
    future: [],
    requests: [],
  },
  hotelItineraries: {
    canceled: [],
    past: [],
    present: [],
    future: [],
    requests: [],
  },
  homeItineraries: {
    canceled: [],
    past: [],
    present: [],
    future: [],
    requests: [],
  },
  carItineraries: {
    canceled: [],
    past: [],
    present: [],
    future: [],
    requests: [],
  },
  packageItineraries: {
    canceled: [],
    past: [],
    present: [],
    future: [],
    requests: [],
  },
  airports: {},
  priceFreezeAirports: {},
  airlines: {},
  priceFreezeAirlines: {},
  watches: [],
  deleteWatchCallState: CallState.NotCalled,
  updateWatchCallState: CallState.NotCalled,
  listWatchCallState: CallState.NotCalled,
  listPriceFreezeCallState: CallState.NotCalled,
  listHotelPriceFreezeCallState: CallState.NotCalled,
  fetchFlightsCallState: {
    canceled: CallState.NotCalled,
    past: CallState.NotCalled,
    present: CallState.NotCalled,
    future: CallState.NotCalled,
    requests: CallState.NotCalled,
  },
  fetchHotelsCallState: {
    canceled: CallState.NotCalled,
    past: CallState.NotCalled,
    present: CallState.NotCalled,
    future: CallState.NotCalled,
    requests: CallState.NotCalled,
  },
  fetchHomesCallState: {
    canceled: CallState.NotCalled,
    past: CallState.NotCalled,
    present: CallState.NotCalled,
    future: CallState.NotCalled,
    requests: CallState.NotCalled,
  },
  fetchCarsCallState: {
    canceled: CallState.NotCalled,
    past: CallState.NotCalled,
    present: CallState.NotCalled,
    future: CallState.NotCalled,
    requests: CallState.NotCalled,
  },
  fetchPackagesCallState: {
    canceled: CallState.NotCalled,
    past: CallState.NotCalled,
    present: CallState.NotCalled,
    future: CallState.NotCalled,
    requests: CallState.NotCalled,
  },
  acceptScheduleChangeCallState: CallState.NotCalled,
  denyScheduleChangeCallState: CallState.NotCalled,
  openModal: { type: null, selectedItinerary: null },
  priceFreezes: [],
  hotelPriceFreezes: [],
  requestPassengerEdit: null,
  selectedCfar: null,
  selectedCfarItineraryId: null,
  fetchFlightCfarInfoCallState: CallState.NotCalled,
  flightCfarCancellationResponse: null,
  confirmFlightCfarCancellationCallState: CallState.NotCalled,
  hotelSearchFromDate: null,
  hotelSearchUntilDate: null,
  hotelSearchLocationResult: null,
  hotelSearchAdultsCount: 2,
  hotelSearchChildrenCount: [],
  hotelSelectedLodgingIndex: null,
  hotelAvailabilityCallState: HotelAvailabilityCallState.NotCalled,
  flightToHotelCrossSellTripCategory: TripCategory.ROUND_TRIP,
  hotelCfarCancellationPolicyScenario: null,
  hotelCfarCancellationPolicyCallState: CallState.NotCalled,
  selectedHotelCfarReservationId: null,
  confirmHotelCfarCancellationCallState: CallState.NotCalled,
  crossSellLatency: null,
  paymentMethods: [],
  listPaymentMethodCallState: CallState.NotCalled,
  tripRequests: {
    cars: [],
    flights: [],
    hotels: [],
  },
  searchQuery: "",
  crossSellOffers: [],
  fetchHotelCrossSellCallState: CallState.NotCalled,
};

export function reducer(
  state: ITripsListState = initialState,
  action: actions.TripsListActions
): ITripsListState {
  switch (action.type) {
    case actionTypes.SET_TRIPS_LIST_EXPERIMENTS:
      return { ...state, experiments: action.experiments };

    case actionTypes.SET_MY_TRIPS_FILTER:
      const { tripsFilter } = action;
      return { ...state, tripsFilter };

    case actionTypes.FETCH_FLIGHTS:
      const requestState = action.request.states;
      switch (true) {
        case requestState.includes(FlightItineraryState.Canceled) &&
          requestState.includes(FlightItineraryState.Past):
          return {
            ...state,
            fetchFlightsCallState: {
              ...state.fetchFlightsCallState,
              past: CallState.InProcess,
              canceled: CallState.InProcess,
            },
          };
        case requestState.includes(FlightItineraryState.Present) &&
          requestState.includes(FlightItineraryState.Future):
          return {
            ...state,
            fetchFlightsCallState: {
              ...state.fetchFlightsCallState,
              present: CallState.InProcess,
              future: CallState.InProcess,
            },
          };
        default:
          return { ...state };
      }

    case actionTypes.FETCH_FLIGHTS_FAILED:
      const itineraryTypes = action.request.states;
      switch (true) {
        case itineraryTypes.includes(FlightItineraryState.Canceled) &&
          itineraryTypes.includes(FlightItineraryState.Past):
          return {
            ...state,
            fetchFlightsCallState: {
              ...state.fetchFlightsCallState,
              past: CallState.Failed,
              canceled: CallState.Failed,
            },
          };
        case itineraryTypes.includes(FlightItineraryState.Present) &&
          itineraryTypes.includes(FlightItineraryState.Future):
          return {
            ...state,
            fetchFlightsCallState: {
              ...state.fetchFlightsCallState,
              present: CallState.Failed,
              future: CallState.Failed,
            },
          };
        default:
          return { ...state };
      }

    case actionTypes.SET_FLIGHTS:
      switch (true) {
        case action.itineraryTypes.includes(FlightItineraryState.Canceled) &&
          action.itineraryTypes.includes(FlightItineraryState.Past):
          return {
            ...state,
            fetchFlightsCallState: {
              ...state.fetchFlightsCallState,
              past: CallState.Success,
              canceled: CallState.Success,
            },
            itineraries: action.flights,
            airports: action.airports,
            airlines: action.airlines,
          };
        case action.itineraryTypes.includes(FlightItineraryState.Present) &&
          action.itineraryTypes.includes(FlightItineraryState.Future):
          return {
            ...state,
            fetchFlightsCallState: {
              ...state.fetchFlightsCallState,
              present: CallState.Success,
              future: CallState.Success,
            },
            itineraries: action.flights,
            airports: action.airports,
            airlines: action.airlines,
          };
        default:
          return { ...state };
      }
    case actionTypes.FETCH_HOTELS:
      const hotelRequestState = action.request.states;
      switch (true) {
        case hotelRequestState.includes(HotelItineraryState.Canceled) &&
          hotelRequestState.includes(HotelItineraryState.Past):
          return {
            ...state,
            fetchHotelsCallState: {
              ...state.fetchHotelsCallState,
              past: CallState.InProcess,
              canceled: CallState.InProcess,
            },
          };
        case hotelRequestState.includes(HotelItineraryState.Present) &&
          hotelRequestState.includes(HotelItineraryState.Future):
          return {
            ...state,
            fetchHotelsCallState: {
              ...state.fetchHotelsCallState,
              present: CallState.InProcess,
              future: CallState.InProcess,
            },
          };

        default:
          return { ...state };
      }

    case actionTypes.FETCH_HOTELS_FAILED:
      const hotelFailedRequestState = action.request.states;
      switch (true) {
        case hotelFailedRequestState.includes(HotelItineraryState.Canceled) &&
          hotelFailedRequestState.includes(HotelItineraryState.Past):
          return {
            ...state,
            fetchHotelsCallState: {
              ...state.fetchHotelsCallState,
              past: CallState.Failed,
              canceled: CallState.Failed,
            },
          };
        case hotelFailedRequestState.includes(HotelItineraryState.Present) &&
          hotelFailedRequestState.includes(HotelItineraryState.Future):
          return {
            ...state,
            fetchHotelsCallState: {
              ...state.fetchHotelsCallState,
              present: CallState.Failed,
              future: CallState.Failed,
            },
          };
        default:
          return { ...state };
      }

    case actionTypes.SET_HOTELS:
      switch (true) {
        case action.itineraryTypes.includes(HotelItineraryState.Canceled) &&
          action.itineraryTypes.includes(HotelItineraryState.Past):
          return {
            ...state,
            fetchHotelsCallState: {
              ...state.fetchHotelsCallState,
              past: CallState.Success,
              canceled: CallState.Success,
            },
            hotelItineraries: action.hotels,
          };
        case action.itineraryTypes.includes(HotelItineraryState.Present) &&
          action.itineraryTypes.includes(HotelItineraryState.Future):
          return {
            ...state,
            fetchHotelsCallState: {
              ...state.fetchHotelsCallState,
              present: CallState.Success,
              future: CallState.Success,
            },
            hotelItineraries: action.hotels,
          };
        default:
          return { ...state };
      }
    case actionTypes.FETCH_HOMES:
      return {
        ...state,
        fetchHomesCallState: {
          ...state.fetchHomesCallState,
          past: CallState.InProcess,
          canceled: CallState.InProcess,
          present: CallState.InProcess,
          future: CallState.InProcess,
        },
      };

    case actionTypes.FETCH_HOMES_FAILED:
      return {
        ...state,
        fetchHomesCallState: {
          ...state.fetchHomesCallState,
          past: CallState.Failed,
          canceled: CallState.Failed,
          present: CallState.Failed,
          future: CallState.Failed,
        },
      };

    case actionTypes.SET_HOMES:
      return {
        ...state,
        fetchHomesCallState: {
          ...state.fetchHomesCallState,
          past: CallState.Success,
          canceled: CallState.Success,
          present: CallState.Success,
          future: CallState.Success,
        },
        homeItineraries: action.homes,
      };

    case actionTypes.FETCH_CARS:
      const carRequestState = action.request.states;
      switch (true) {
        case carRequestState.includes(CarReservationState.Canceled) &&
          carRequestState.includes(CarReservationState.Past):
          return {
            ...state,
            fetchCarsCallState: {
              ...state.fetchCarsCallState,
              past: CallState.InProcess,
              canceled: CallState.InProcess,
            },
          };
        case carRequestState.includes(CarReservationState.Present) &&
          carRequestState.includes(CarReservationState.Future):
          return {
            ...state,
            fetchCarsCallState: {
              ...state.fetchCarsCallState,
              present: CallState.InProcess,
              future: CallState.InProcess,
            },
          };
        default:
          return { ...state };
      }

    case actionTypes.FETCH_CARS_FAILED:
      const carFailedRequestState = action.request.states;
      switch (true) {
        case carFailedRequestState.includes(CarReservationState.Canceled) &&
          carFailedRequestState.includes(CarReservationState.Past):
          return {
            ...state,
            fetchCarsCallState: {
              ...state.fetchCarsCallState,
              past: CallState.Failed,
              canceled: CallState.Failed,
            },
          };
        case carFailedRequestState.includes(CarReservationState.Present) &&
          carFailedRequestState.includes(CarReservationState.Future):
          return {
            ...state,
            fetchCarsCallState: {
              ...state.fetchCarsCallState,
              present: CallState.Failed,
              future: CallState.Failed,
            },
          };
        default:
          return { ...state };
      }

    case actionTypes.SET_CARS:
      const { response: carResponse } = action;
      if (
        carResponse.CarItineraryResponse === CarItineraryResponseEnum.Failure
      ) {
        switch (true) {
          case action.reservationTypes?.includes(CarReservationState.Past) &&
            action.reservationTypes?.includes(CarReservationState.Canceled):
            return {
              ...state,
              fetchCarsCallState: {
                ...state.fetchCarsCallState,
                past: CallState.Failed,
                canceled: CallState.Failed,
              },
            };
          case action.reservationTypes?.includes(CarReservationState.Present) &&
            action.reservationTypes?.includes(CarReservationState.Future):
            return {
              ...state,
              fetchCarsCallState: {
                ...state.fetchCarsCallState,
                present: CallState.Failed,
                future: CallState.Failed,
              },
            };

          default:
            return { ...state };
        }
      } else {
        switch (true) {
          case action.reservationTypes?.includes(CarReservationState.Past) &&
            action.reservationTypes?.includes(CarReservationState.Canceled):
            return {
              ...state,
              fetchCarsCallState: {
                ...state.fetchCarsCallState,
                past: CallState.Success,
                canceled: CallState.Success,
              },
              carItineraries: (carResponse as CarItineraryResponseSuccess)
                .reservations,
            };
          case action.reservationTypes?.includes(CarReservationState.Present) &&
            action.reservationTypes?.includes(CarReservationState.Future):
            return {
              ...state,
              fetchCarsCallState: {
                ...state.fetchCarsCallState,
                present: CallState.Success,
                future: CallState.Success,
              },
              carItineraries: (carResponse as CarItineraryResponseSuccess)
                .reservations,
            };
          default:
            return { ...state };
        }
      }

    case actionTypes.FETCH_PACKAGES:
      return {
        ...state,
        fetchPackagesCallState: {
          ...state.fetchPackagesCallState,
          past: CallState.InProcess,
          canceled: CallState.InProcess,
          present: CallState.InProcess,
          future: CallState.InProcess,
        },
      };

    case actionTypes.FETCH_PACKAGES_FAILED:
      return {
        ...state,
        fetchPackagesCallState: {
          ...state.fetchPackagesCallState,
          past: CallState.Failed,
          canceled: CallState.Failed,
          present: CallState.Failed,
          future: CallState.Failed,
        },
      };

    case actionTypes.SET_PACKAGES:
      return {
        ...state,
        fetchPackagesCallState: {
          ...state.fetchPackagesCallState,
          past: CallState.Success,
          canceled: CallState.Success,
          present: CallState.Success,
          future: CallState.Success,
        },
        packageItineraries: action.packages,
      };
    case actionTypes.UPDATE_WATCH:
      return {
        ...state,
        updateWatchCallState: CallState.InProcess,
      };

    case actionTypes.SET_UPDATE_WATCH_CALL_STATE:
      return {
        ...state,
        updateWatchCallState: action.callState,
      };

    case actionTypes.DELETE_WATCH:
      return {
        ...state,
        deleteWatchCallState: CallState.InProcess,
      };

    case actionTypes.SET_DELETE_WATCH_CALL_STATE:
      return {
        ...state,
        deleteWatchCallState: action.callState,
      };

    case actionTypes.ACCEPT_SCHEDULE_CHANGE:
      return {
        ...state,
        acceptScheduleChangeCallState: CallState.InProcess,
      };

    case actionTypes.DENY_SCHEDULE_CHANGE:
      return {
        ...state,
        denyScheduleChangeCallState: CallState.InProcess,
      };

    case actionTypes.SET_ACCEPT_SCHEDULE_CHANGE_CALL_STATE:
      return {
        ...state,
        acceptScheduleChangeCallState: action.callState,
      };

    case actionTypes.SET_DENY_SCHEDULE_CHANGE_CALL_STATE:
      return {
        ...state,
        denyScheduleChangeCallState: action.callState,
      };

    case actionTypes.LIST_WATCHES:
      return {
        ...state,
        listWatchCallState: CallState.InProcess,
      };

    case actionTypes.LIST_PRICE_FREEZE:
      return {
        ...state,
        listPriceFreezeCallState: CallState.InProcess,
      };

    case actionTypes.SET_LIST_PRICE_FREEZE_CALL_STATE:
      return {
        ...state,
        listPriceFreezeCallState: action.callState,
      };

    case actionTypes.SET_PRICE_FREEZE:
      return {
        ...state,
        priceFreezes: action.priceFreezes || [],
        priceFreezeAirlines: action.tripContext.airlines,
        priceFreezeAirports: action.tripContext.airports,
        listPriceFreezeCallState: CallState.Success,
      };

    case actionTypes.LIST_HOTEL_PRICE_FREEZE:
      return {
        ...state,
        listHotelPriceFreezeCallState: CallState.InProcess,
      };

    case actionTypes.SET_LIST_HOTEL_PRICE_FREEZE_CALL_STATE:
      return {
        ...state,
        listHotelPriceFreezeCallState: action.callState,
      };

    case actionTypes.SET_HOTEL_PRICE_FREEZES:
      return {
        ...state,
        hotelPriceFreezes: action.hotelPriceFreezes || [],
        listHotelPriceFreezeCallState: CallState.Success,
      };

    case actionTypes.SET_LIST_WATCHES_CALL_STATE:
      return {
        ...state,
        listWatchCallState: action.callState,
      };

    case actionTypes.SET_WATCHES:
      return {
        ...state,
        watchContext: action.watches.tripContext,
        watches: action.watches.watches || [],
      };

    case actionTypes.SET_SELECTED_FLIGHT:
      return {
        ...state,
        selectedFlight: action.selectedFlight,
      };

    case actionTypes.SET_OPEN_MODAL:
      return {
        ...state,
        openModal: action.openModal,
      };

    case actionTypes.SET_SELECTED_REQUEST_PASSENGER_EDIT:
      return {
        ...state,
        requestPassengerEdit: action.requestPassengerEdit || null,
      };

    case actionTypes.SET_SELECTED_HOTEL:
      return {
        ...state,
        selectedHotel: action.selectedHotel,
      };

    case actionTypes.SET_SELECTED_HOME:
      return {
        ...state,
        selectedHome: action.selectedHome,
      };

    case actionTypes.SET_SELECTED_CAR:
      return {
        ...state,
        selectedCar: action.selectedCar,
      };

    case actionTypes.SET_SELECTED_PACKAGE:
      return {
        ...state,
        selectedPackage: action.selectedPackage,
      };
    case actionTypes.SET_SELECTED_PACKAGE_HOTEL:
      return {
        ...state,
        selectedPackageHotel: action.selectedHotel,
      };

    case actionTypes.SET_SELECTED_PACKAGE_FLIGHT:
      return {
        ...state,
        selectedPackageFlight: action.selectedFlight,
      };

    case actionTypes.FETCH_FLIGHT_CFAR_CANCELLATION_INFO_V3:
      return {
        ...state,
        fetchFlightCfarInfoCallState: CallState.InProcess,
        selectedCfar: null,
        selectedCfarItineraryId: action.itineraryId,
      };

    case actionTypes.SET_FLIGHT_CFAR_CANCELLATION_INFO_V3_RESPONSE:
      return {
        ...state,
        fetchFlightCfarInfoCallState: CallState.Success,
        selectedCfar: action.response,
      };

    case actionTypes.SET_FETCH_FLIGHT_CFAR_CANCELLATION_INFO_V3_CALL_STATE_FAILED:
      return {
        ...state,
        fetchFlightCfarInfoCallState: CallState.Failed,
      };

    case actionTypes.SET_FETCH_FLIGHT_CFAR_CANCELLATION_INFO_V3_CALL_STATE_NOT_CALLED:
      return {
        ...state,
        fetchFlightCfarInfoCallState: CallState.NotCalled,
        selectedCfar: null,
        selectedCfarItineraryId: null,
      };

    case actionTypes.CONFIRM_FLIGHT_CFAR_CANCELLATION:
      return {
        ...state,
        flightCfarCancellationResponse: null,
        confirmFlightCfarCancellationCallState: CallState.InProcess,
      };

    case actionTypes.SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_RESPONSE:
      return {
        ...state,
        flightCfarCancellationResponse: action.response,
        confirmFlightCfarCancellationCallState: CallState.Success,
      };

    case actionTypes.SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_CALL_STATE_FAILED:
      return {
        ...state,
        confirmFlightCfarCancellationCallState: CallState.Failed,
      };

    case actionTypes.SET_CONFIRM_FLIGHT_CFAR_CANCELLATION_CALL_STATE_NOT_CALLED:
      return {
        ...state,
        confirmFlightCfarCancellationCallState: CallState.NotCalled,
      };

    case actionTypes.SET_CROSS_SELL_HOTEL_AVAILABILITY_RESULTS:
      return {
        ...state,
        crossSellLatency: action.latency,
        hotelAvailabilityResponse: {
          ...action.payload,
          lodgings: [
            ...(state.hotelAvailabilityResponse?.lodgings || []),
            ...action.payload.lodgings,
          ],
          bestOverallOffer:
            action.payload.bestOverallOffer ??
            state.hotelAvailabilityResponse?.bestOverallOffer,
        },
        hotelAvailabilityCallState: HotelAvailabilityCallState.Complete,
      };

    case actionTypes.SET_SEARCHED_DATES:
      return {
        ...state,
        hotelSearchFromDate: action.searchedFromDate,
        hotelSearchUntilDate: action.searchedUntilDate,
      };
    case actionTypes.SET_SEARCHED_LOCATION_RESULT:
      return {
        ...state,
        hotelSearchLocationResult: action.searchedLocationResult,
      };
    case actionTypes.SET_SEARCHED_OCCUPANCY_COUNTS:
      const { adults, children } = action.counts as GuestsSelection;
      return {
        ...state,
        hotelSearchAdultsCount: adults,
        hotelSearchChildrenCount: children,
      };
    case actionTypes.SET_SELECTED_LODGING_INDEX:
      return {
        ...state,
        hotelSelectedLodgingIndex: action.index,
      };
    case actionTypes.SET_FLIGHT_TO_HOTEL_XSELL_TRIP_CATEGORY:
      return { ...state, flightToHotelCrossSellTripCategory: action.category };
    case actionTypes.FETCH_HOTEL_CFAR_CANCELLATION_POLICY:
      return {
        ...state,
        hotelCfarCancellationPolicyCallState: CallState.InProcess,
        hotelCfarCancellationPolicyScenario: null,
        selectedHotelCfarReservationId: action.request.reservationId,
      };
    case actionTypes.SET_HOTEL_CFAR_CANCELLATION_POLICY_SCENARIO:
      return {
        ...state,
        hotelCfarCancellationPolicyScenario:
          action.hotelCfarCancellationPolicyScenario,
        hotelCfarCancellationPolicyCallState: CallState.Success,
      };
    case actionTypes.SET_HOTEL_CFAR_CANCELLATION_POLICY_CALL_STATE:
      return {
        ...state,
        hotelCfarCancellationPolicyCallState: action.callState,
      };
    case actionTypes.RESET_HOTEL_CFAR_CANCELLATION:
      return {
        ...state,
        hotelCfarCancellationPolicyScenario: null,
        hotelCfarCancellationPolicyCallState: CallState.NotCalled,
        selectedHotelCfarReservationId: "",
      };
    case actionTypes.CONFIRM_HOTEL_CFAR_CANCELLATION:
      return {
        ...state,
        confirmHotelCfarCancellationCallState: CallState.InProcess,
      };
    case actionTypes.SET_CONFIRM_HOTEL_CFAR_CANCELLATION_CALL_STATE:
      return {
        ...state,
        confirmHotelCfarCancellationCallState: action.callState,
      };
    case actionTypes.SET_HOTEL_CFAR_CANCELLED:
      return {
        ...state,
        hotelCfarCancellationPolicyScenario: {
          HotelCfarCancelScenario: HotelCfarCancelScenarioEnum.Canceled,
        },
        confirmHotelCfarCancellationCallState: CallState.Success,
      };
    case actionTypes.LIST_PAYMENT_METHODS:
      return {
        ...state,
        listPaymentMethodCallState: CallState.InProcess,
      };

    case actionTypes.SET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.paymentMethods,
        listPaymentMethodCallState: CallState.Success,
      };

    case actionTypes.SET_PAYMENT_METHODS_CALL_STATE_FAILED:
      return {
        ...state,
        listPaymentMethodCallState: CallState.Failed,
      };

    case actionTypes.FETCH_PENDING_REQUESTS:
      return {
        ...state,
        fetchHotelsCallState: {
          ...state.fetchHotelsCallState,
          requests: CallState.InProcess,
        },
        fetchFlightsCallState: {
          ...state.fetchFlightsCallState,
          requests: CallState.InProcess,
        },
        fetchCarsCallState: {
          ...state.fetchCarsCallState,
          requests: CallState.InProcess,
        },
      };
    case actionTypes.FETCH_PENDING_REQUESTS_FAILED:
      return {
        ...state,
        fetchHotelsCallState: {
          ...state.fetchHotelsCallState,
          requests: CallState.Failed,
        },
        fetchFlightsCallState: {
          ...state.fetchFlightsCallState,
          requests: CallState.Failed,
        },
        fetchCarsCallState: {
          ...state.fetchCarsCallState,
          requests: CallState.Failed,
        },
      };
    case actionTypes.SET_PENDING_REQUESTS:
      return {
        ...state,
        fetchHotelsCallState: {
          ...state.fetchHotelsCallState,
          requests: CallState.Success,
        },
        fetchFlightsCallState: {
          ...state.fetchFlightsCallState,
          requests: CallState.Success,
        },
        fetchCarsCallState: {
          ...state.fetchCarsCallState,
          requests: CallState.Success,
        },
        tripRequests: action.requests,
      };
    case actionTypes.SET_TRIP_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: action.searchQuery,
      };
    case actionTypes.SET_CROSS_SELL_OFFERS:
      return { ...state, crossSellOffers: action.offers };
    case actionTypes.SET_FETCH_ACTIVE_HOTEL_CROSS_SELL_CALLSTATE:
      return { ...state, fetchHotelCrossSellCallState: action.callState };
    default:
      return state;
  }
}

export * from "./selectors";
