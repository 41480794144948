import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../reducers/types";
import {
  fetchFlights,
  fetchHotels,
  fetchCars,
  listWatches,
  setTripsFilter,
  listPriceFreeze,
  listHotelPriceFreeze,
  fetchHomes,
  listPaymentMethods,
  fetchPendingRequests,
  setTripSearchQuery,
  fetchPackages,
} from "./actions/actions";
import { fetchFlightDisruptions } from "../DisruptionProtection/actions/actions";
import { TripsList } from "./component";
import {
  getSelectedCar,
  getSelectedFlight,
  getSelectedHotel,
  getTripsFilter,
  getAreTripsLoading,
  hasFetchTripsError,
  hasTripsToDisplay,
  getOpenModal,
  activeCfarCountSelector,
  getTripsCounts,
  getSelectedHome,
  isPackagesEnabledSelector,
  getSelectedPackageHotel,
  getSelectedPackageFlight,
} from "./reducer";
import {
  myTripsPartialFlightsListDpExerciseFactsPropertiesSelector,
  myTripsPartialEligibilityDpExerciseFactsPropertiesSelector,
  getFetchFlightDisruptionsCallState,
  getDpAttachedFlightItineraryIds,
} from "../DisruptionProtection/reducer";

const mapStateToProps = (state: IStoreState) => ({
  tripsFilter: getTripsFilter(state),
  selectedFlight: getSelectedFlight(state),
  selectedHotel: getSelectedHotel(state),
  selectedCar: getSelectedCar(state),
  selectedHome: getSelectedHome(state),
  selectedPackageHotel: getSelectedPackageHotel(state),
  selectedPackageFlight: getSelectedPackageFlight(state),
  areTripsLoading: getAreTripsLoading(state),
  hasError: hasFetchTripsError(state),
  hasTripsToDisplay: hasTripsToDisplay(state),
  activeCfarCount: activeCfarCountSelector(state),
  openModal: getOpenModal(state),
  partialFlightsListDpExerciseFactsProperties:
    myTripsPartialFlightsListDpExerciseFactsPropertiesSelector(state),
  partialEligibilityDpExerciseFactsProperties:
    myTripsPartialEligibilityDpExerciseFactsPropertiesSelector(state),
  fetchFlightDisruptionsCallState: getFetchFlightDisruptionsCallState(state),
  tripsCounts: getTripsCounts(state),
  dpAttachedFlightItineraryIds: getDpAttachedFlightItineraryIds(state),
  isPackagesEnabled: isPackagesEnabledSelector(state),
});

const mapDispatchToProps = {
  fetchCars,
  fetchFlights,
  fetchHotels,
  listWatches,
  setTripsFilter,
  listPriceFreeze,
  listHotelPriceFreeze,
  fetchFlightDisruptions,
  fetchHomes,
  listPaymentMethods,
  fetchPendingRequests,
  setTripSearchQuery,
  fetchPackages,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TripsListConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedTripsList = connector(withRouter(TripsList));
